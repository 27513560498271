import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';




@Injectable({
  providedIn: 'root'
})
export class CountrySelectService {


  private executeQuery<T>(query: string, params?: any[]) {

    query = "https://www.whiberica.es/APIWH/rest/index.php/" + query;

    //PONER PARA PRUEBAS
    // query = "https://www.whiberica.es/APIWH-TEST/rest/index.php/" + query;


    return this.http.post<any>(query, params /*, { headers }*/);

  }

  private executeQuery1<T>(query: string, params: any[]) {

    query = "https://www.whiberica.es/APIWH/rest/index.php/" + query;

    //PONER PARA PRUEBAS
    // query = "https://www.whiberica.es/APIWH-TEST/rest/index.php/" + query;

    return this.http.post<any>(query, params /*, { headers }*/);

  }



  constructor(private http: HttpClient) { }

  getPaises() {
    return this.executeQuery('/Paises');
  }

  getProvincias(params) {

    return this.executeQuery('/Provincias', params);
  }

  getSiglas(params) {
    return this.executeQuery('/Siglas', params);
  }


  getAsistencia(params) {
    // console.log(params);

    return this.executeQuery1('/AsistenciaTecnica', params);
    // return this.executeQuery1('/AsistenciaTecnica1', params);
  }

}
