import { Component, OnInit, Input } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { NgForm } from '@angular/forms';
import { CrudTablasService } from '../../servicios/crud-tablas.service';

@Component({
  selector: 'app-modal-image',
  templateUrl: './modal-image.page.html',
  styleUrls: ['./modal-image.page.scss'],
})
export class ModalImagePage implements OnInit {

  @Input() tabla: string = "";
  @Input() texto: string = "";
  @Input() datos: string = "";
  @Input() ruta: string = "";

  sliders:any = {}

  
  file1: File[] = [];
  file2: File[] = [];


  constructor(    public alertController: AlertController,
                  private crudTabla: CrudTablasService,
                  public modalController : ModalController
                  ) { }

  ngOnInit() {
    console.log("RUTA", this.ruta);
  }
  async onSubmit( formulario: NgForm) {
    let imagen = ( this.ruta === "ruta1" ) ? this.file1[0] : this.file2[0] ; 
    this.crudTabla.cambiarImagenSlider( this.datos["id"], imagen, this.ruta ).subscribe( (ans: any) => {
      this.presentAlert(ans['message']);
    });
    this.modalController.dismiss(
      this.tabla
    );
  }




  onSelect(event, number) {
    switch (number) {
      case 1:
        this.file1 = event.addedFiles;
        this.sliders["ruta1"] = this.file1[0].name;
        break;
      case 2:
        this.file2 = event.addedFiles;
        this.sliders["ruta2"] = this.file2[0].name;
        break;
      default:
        break;
    }
  }

  onRemoveFile1(f) {
    this.file1 = [];
    this.sliders["ruta1"] = "";
  }

  onRemoveFile2(f) {
    this.file2 = [];
    this.sliders["ruta2"] = "";
  }

  async presentAlert( mensaje) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Información',
      // subHeader: 'Subtitle',
      message: mensaje,
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }
}
