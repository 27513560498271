import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { RutasService } from '../servicios/rutas.service';
import { NgxDropzoneModule } from 'ngx-dropzone';




// shared componentes que se repiten

import { HeaderComponent } from './header/header.component';
import { SlidersComponent } from './sliders/sliders.component';
import { FooterComponent } from './footer/footer.component';
import { ContentComponent } from './content/content.component';
import { LoginService } from '../servicios/login.service';
import { StorageService } from '../servicios/storage.service';


// import { CrearusuarioPage } from '../home/crearusuario/crearusuario.page';
import { LoguinPage } from '../home/loguin/loguin.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { AsistenciatecnicaPage } from '../home/homecliente/asistenciatecnica/asistenciatecnica.page';
// import { MicuentaPage } from '../home/homecliente/micuenta/micuenta.page';
import { MisproductosPage } from '../home/homecliente/misproductos/misproductos.page';

import { NuevoproductoPage } from '../home/homecliente/nuevoproducto/nuevoproducto.page';
import { MenuizquierdaPage } from '../home/homecliente/menuizquierda/menuizquierda.page';
import { MenumicuentaPage } from '../home/homecliente/menumicuenta/menumicuenta.page';
import { CreardireccionenesPage } from '../home/homecliente/creardireccionenes/creardireccionenes.page';

import { IonicSelectableModule } from 'ionic-selectable';
import { MiAccordionComponent } from '../widgets/mi-accordion/mi-accordion.component';
import { CrudPage } from '../ADMIN/crud/crud.page';
import { SANITASPage } from '../ADMIN/sanitas/sanitas.page';
import { InformesPage } from '../ADMIN/informes/informes.page';

// import { ShopPage } from '../home/homecliente/shop/shop.page';




@NgModule({
  declarations: [
    HeaderComponent,
    SlidersComponent,
    FooterComponent,
    SlidersComponent,
    ContentComponent,
    // CrearusuarioPage,
    LoguinPage,
    // AsistenciatecnicaPage,
    // MicuentaPage,
    CrudPage,
    SANITASPage,
    InformesPage,
    MisproductosPage,
    NuevoproductoPage,
    MenuizquierdaPage,
    MenumicuentaPage,
    CreardireccionenesPage,
    MiAccordionComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    IonicSelectableModule,
    NgxDropzoneModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
    HeaderComponent,
    SlidersComponent,
    FooterComponent,
    ContentComponent,
    // CrearusuarioPage,
    LoguinPage,
    // AsistenciatecnicaPage,
    // MicuentaPage,
    CrudPage,
    SANITASPage,
    InformesPage,
    MisproductosPage,
    NuevoproductoPage,
    MenuizquierdaPage,
    MenumicuentaPage,
    CreardireccionenesPage,
    MiAccordionComponent
  ],
  providers: [
    LoginService,
    RutasService,
    StorageService
  ]
})
export class SharedModule { }
