import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController, AlertController } from '@ionic/angular';
import { beforeRead } from '@popperjs/core';
import { CrudTablasService } from '../../servicios/crud-tablas.service';

@Component({
  selector: 'app-modal-add',
  templateUrl: './modal-add.page.html',
  styleUrls: ['./modal-add.page.scss'],
})
export class ModalAddPage implements OnInit {

  @Input() datos: string = "";
  @Input() posicion: number;
  // FACTURA
  file: File[] = [];

  // SLIDERS
  file1: File[] = [];
  file2: File[] = [];


  articulos_gar = {}
  clientes = {
    "Tipo_usuario": "cliente",
  }
  domicilios = {}
  promociones = {}
  provincias = {}
  siglas = {}
  paises = {}
  solicitudes = {}
  shop = {}
  sliders = {
    "activa": "0",
  }

  constructor(private crudTabla: CrudTablasService,
    public alertController: AlertController,
    public modalController: ModalController) {
  }



  ngOnInit() {
    // console.log(this.datos['data']);

  }

  onSelect(event, number) {
    switch (number) {
      case 1:
        this.file1 = event.addedFiles;
        // console.log(this.file1[0].name);
        this.sliders["ruta1"] = this.file1[0].name;
        break;
      case 2:
        this.file2 = event.addedFiles;
        this.sliders["ruta2"] = this.file2[0].name;
        break;
      default:
        break;
    }
  }

  onSelectFactura(event) {
    this.file = event.addedFiles;
    this.articulos_gar["factura"] = this.file[0].name;
  }

  ionViewWillEnter() {
    if (this.datos['accion'] == "edit") {
      switch (this.datos['tabla']) {
        case "articulos_garantia":
          this.articulos_gar = {
            "id": this.datos['data']['id'],
            "CIFDNI": this.datos['data']['CIFDNI']?.trim(),
            "Num_serie": this.datos['data']['NumeroSerie']?.trim(),
            "Referencia": this.datos['data']['Referencia']?.trim(),
            "Fecha": this.datos['data']['fecha'],
            "Factura": this.datos['data']['factura'],
            "Estado": this.datos['data']['estado'],
            "Fin_garantia": this.datos['data']['fin_garantia'],
            "tipo": this.datos['data']['tipo'],
          }
          break;
        case "cliente_w":
          this.clientes["id"] = this.datos['data']['id'];
          this.clientes["CIFDNI"] = this.datos['data']['CIFDNI'];
          this.clientes["Razon_social"] = this.datos['data']['RazonSocial'];
          this.clientes["Nombre"] = this.datos['data']['Nombre'];
          this.clientes["Apellidos"] = this.datos['data']['Apellidos'];
          this.clientes["Nom_comercial"] = this.datos['data']['NombreComercial'];
          this.clientes["Telefono"] = this.datos['data']['Telefono'];
          this.clientes["Email"] = this.datos['data']['Email'];
          this.clientes["Contra"] = "";
          this.clientes["Persona_contacto"] = this.datos['data']['PersonaContacto'];
          this.clientes["Tipo_usuario"] = this.datos['data']['Permiso'];
          this.clientes["Fecha"] = this.datos['data']['Fecha'];
          // this.clientes["Factura"] = this.datos['data']['Factura'];
          this.clientes["Estado"] = this.datos['data']['Estado'];
          this.clientes["checkbox"] = this.datos['data']['checkbox'];
          this.clientes["checkbox1"] = this.datos['data']['checkbox1'];
          this.clientes["checkbox2"] = this.datos['data']['checkbox2'];
          this.clientes["checkbox3"] = this.datos['data']['checkbox3'];
          this.clientes["ceco"] = this.datos['data']['ceco'];
          break;
        case "domicilios_w":
          this.domicilios = {
            "id": this.datos['data']['id'],
            "CIFDNI": this.datos['data']['CIFDNI'],
            "Cod_sigla": this.datos['data']['CodigoSigla'],
            "Escalera": this.datos['data']['Escalera'],
            "Numero": this.datos['data']['Numero'],
            "Piso": this.datos['data']['Piso'],
            "Puerta": this.datos['data']['Puerta'],
            "Letra": this.datos['data']['Letra'],
            "Cod_postal": this.datos['data']['CodPostal'],
            "Calle": this.datos['data']['Calle'],
            "Poblacion": this.datos['data']['Poblacion'],
            "Provincia": this.datos['data']['Provincia'],
            "Pais": this.datos['data']['Pais'],
            "Tipo_dom": this.datos['data']['TipoDomicilio'],
            "Num_dom": this.datos['data']['NumeroDomicilio'],
          }
          break;
        case "paises":
          this.paises = {
            "id": this.datos['data']['id'],
            "Sigla": this.datos['data']['Sigla'],
            "Pais": this.datos['data']['Pais'],
          }
          break;
        case "promociones":
          this.promociones = {
            "id": this.datos['data']['id'],
            "Cod_empresa": this.datos['data']['CodigoEmpresa'],
            "Cod_art": this.datos['data']['CodigoArticulo'],
            "Meses_gar_promo": this.datos['data']['WehadentMesesGarantiaPromo'],
            "Fecha_ini_promo": this.datos['data']['FechaInicioPromocionGarantia12'],
            "Fecha_fin_promo": this.datos['data']['FechaFinPromocionGarantia12'],
          }
          break;
        case "provincias":
          this.provincias = {
            "id": this.datos['data']['id'],
            "Cod_provincia": this.datos['data']['CodigoProvincia'],
            "Provincia": this.datos['data']['Provincia'],
            "Pais": this.datos['data']['Pais'],
          }
          break;
        case "siglas":
          this.siglas = {
            "id": this.datos['data']['id'],
            "Cod_sigla": this.datos['data']['CodigoSigla'],
            "Nom_sigla": this.datos['data']['NombreSigla'],
            "Idioma_sigla": this.datos['data']['IdiomaSigla'],
          }
          break;
        case "solicitudes_w":
          this.solicitudes = {
            "id": this.datos['data']['id'],
            "CIFDNI": this.datos['data']['CIFDNI'],
            "Domicilio": this.datos['data']['Domicilio'],
            "Fecha_solic": this.datos['data']['FechaSolicitud'],
            "Referencia": this.datos['data']['Referencia'],
            "Num_serie": this.datos['data']['NumeroSerie'],
            "Comentarios": this.datos['data']['Comentarios'],
            "Pers_contacto": this.datos['data']['PersonaContacto'],
            "Errores": this.datos['data']['Errores'],
          }
          break;
        case "shop":
          this.shop = {
            "id": this.datos['data']['id'],
            "CodArticulo": this.datos['data']['CodArticulo'],
            "CIFDNI": this.datos['data']['CIFDNI'],
            "FechaCompraGarantia": this.datos['data']['FechaCompraGarantia'],
            "TipoGar": this.datos['data']['TipoGar'],
            "Precio": this.datos['data']['Precio'],
          }
          console.log(this.shop);
          break;
        case "sliders":
          this.sliders["id"] = this.datos['data']['id'];
          this.sliders["txt_button"] = this.datos['data']['txt_button'];
          this.sliders["url_button"] = this.datos['data']['url_button'];
          this.sliders["nombre"] = this.datos['data']['nombre'];
          this.sliders["activa"] = this.datos['data']['activa'];
          break;
        default:
          break;
      }
    }

  }

  cambiarActivoSlider(event) {
    console.log(event.detail.value);
    this.sliders["activa"] = event.detail.value;
  }

  async onSubmit(formulario: NgForm) {
    let data = {
      "tabla": this.datos['tabla'],
      "datos": {}
    };
    switch (this.datos['tabla']) {
      case "articulos_garantia":
        data.datos = JSON.stringify({
          "datos": this.articulos_gar,
          "posicion": this.posicion,
        });
        break;
      case "cliente_w":
        // this.clientes["Factura"] = this.clientes["Factura"];
        this.clientes["Estado"] = this.clientes["Estado"];
        console.log("datos", this.clientes);
        data.datos = JSON.stringify({
          "datos": this.clientes,
          "posicion": this.posicion,
        });
        break;
      case "domicilios_w":
        data.datos = JSON.stringify({
          "datos": this.domicilios,
          "posicion": this.posicion,
        });
        break;
      case "paises":
        data.datos = JSON.stringify({
          "datos": this.paises,
          "posicion": this.posicion,
        });
        break;
      case "promociones":
        data.datos = JSON.stringify({
          "datos": this.promociones,
          "posicion": this.posicion,
        });
        break;
      case "provincias":
        data.datos = JSON.stringify({
          "datos": this.provincias,
          "posicion": this.posicion,
        });
        break;
      case "siglas":
        data.datos = JSON.stringify({
          "datos": this.siglas,
          "posicion": this.posicion,
        });
        break;
      case "solicitudes_w":
        data.datos = JSON.stringify({
          "datos": this.solicitudes,
          "posicion": this.posicion,
        });
        break;
      case "shop":
        data.datos = JSON.stringify({
          "datos": this.shop,
          "posicion": this.posicion,
        });
        break;
      case "sliders":
        data.datos = JSON.stringify({
          "datos": this.sliders,
          "posicion": this.posicion,
        });
        break;
      default:
        break;
    }
    if (this.datos['accion'] === "edit") {
      console.log(this.datos);
      this.crudTabla.editInDatabase(data).subscribe((ans: any) => {
        this.presentAlert(ans['message']);
      });
    } else {
      switch (this.datos['tabla']) {
        case "sliders":
          this.crudTabla.addToDatabase(data, this.file1[0], this.file2[0]).subscribe((ans: any) => {
            this.presentAlert(ans['message']);
          });
          break;
        case "articulos_garantia":
          this.crudTabla.addToDatabase(data, this.file[0]).subscribe((ans: any) => {
            this.presentAlert(ans['message']);
          });
          break;
        default:
          this.crudTabla.addToDatabase(data).subscribe((ans: any) => {
            console.log("TEST");
            this.presentAlert(ans['message']);
          });
          break;
      }
    }
    // let aux:any = JSON.parse( String(data.datos));
    // console.log("QAA", aux );
    // console.log("pos", this.posicion );
    // data.datos = JSON.parse( data.datos );
    this.modalController.dismiss("ok");
  }

  async presentAlert(mensaje) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Información',
      // subHeader: 'Subtitle',
      message: mensaje,
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    // console.log('onDidDismiss resolved with role', role);
  }

  onRemoveFile1(f) {
    this.file1 = [];
    this.sliders["ruta1"] = "";
  }

  onRemoveFile2(f) {
    this.file2 = [];
    this.sliders["ruta2"] = "";
  }


  cambiarTipoUsuario(event) {
    this.clientes.Tipo_usuario = event.target.value;
    // console.log(event.target.value);
  }

}
