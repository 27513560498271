import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RutasService } from './rutas.service';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsuarioavanzadoService {

  httpHeader = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };


  RUTA = "ReservaVLC.php";
  RUTAENPRODUCCION = "EnProduccion";
  RUTAENTRANSITO = "EnTransito";
  RUTAGENERACIONPARTE = "GeneracionParte.php";
  RUTAINCIDENCIAS = "Incidencias";
  RUTAINFORMES = "Informes";
  RUTAINCIDENCIASDATOSCOMPLETOS = "DatosIncidencias";
  RUTACAMBIOCONTRASENA = "Cambiocontrasena";



  constructor(
    public _apiruta: RutasService,
    private http: HttpClient
  ) { }


  httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json',
      'Content-Type': 'x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization,Origin, X-Requested-With, Content-Type, Accept',
      'Access-Control-Allow-Credentials': 'true',
      method: 'POST'
    })
  };

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }



  listarReservaVlc() {

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');


    const payload = new HttpParams()


    // console.log(p);


    return this.http.post(this._apiruta.BASE_RUTASQL + this.RUTA, payload)
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));

  }


  listarEnProduccion() {

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');


    const payload = new HttpParams()


    // console.log(p);


    return this.http.post(this._apiruta.BASE_RUTA + this.RUTAENPRODUCCION, payload)
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));

  }


  listarEnPartesSage() {

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');


    const payload = new HttpParams()


    // console.log(p);


    return this.http.post(this._apiruta.BASE_RUTASQL + this.RUTAGENERACIONPARTE, payload)
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));

  }


  listarEnIncidencias(datos) {

    // console.log(datos);


    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');


    const payload = new HttpParams()
      .set('datos', datos)


    // console.log(p);


    return this.http.post(this._apiruta.BASE_RUTA + this.RUTAINCIDENCIAS, payload)
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));

  }



  listarEnTransito() {

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');


    const payload = new HttpParams()


    // console.log(p);


    return this.http.post(this._apiruta.BASE_RUTA + this.RUTAENTRANSITO, payload)
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));

  }


  listarDatosTransito(datos) {

    // console.log(datos);


    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');


    const payload = new HttpParams()
      .set('datos', datos)


    // console.log(p);


    return this.http.post(this._apiruta.BASE_RUTA + this.RUTAINCIDENCIASDATOSCOMPLETOS, payload)
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));

  }



  listarEnInformes(datos) {

    // console.log(datos);


    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');


    const payload = new HttpParams()
      .set('datos', datos)


    // console.log(p);


    return this.http.post(this._apiruta.BASE_RUTA + this.RUTAINFORMES, payload)
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));

  }


  cambiocontrasena(data: { id: any, Password: string }): Observable<any> {
    return this.http.post(`${this._apiruta.BASE_RUTA + this.RUTACAMBIOCONTRASENA}`, data);
  }


  cambiocondtrasena(datos) {

    // console.log(datos);


    // var headers = new HttpHeaders();
    // headers.append("Accept", 'application/json');
    // headers.append('Content-Type', 'application/json');


    // const payload = new HttpParams()
    //   .set('asistencia', asistencia)
    //   .set('productos', productos)
    //   .set('direcciones', direcciones)


    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');


    const payload = new HttpParams()
      .set('datos', datos)


    console.log(payload);


    return this.http.post(this._apiruta.BASE_RUTA + this.RUTACAMBIOCONTRASENA, payload)
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));

  }



}
