import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { LoginService } from 'src/app/servicios/login.service';
import { LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { HomeclientePage } from '../homecliente/homecliente.page';
import { Storage } from '@ionic/storage-angular';
import { CrearusuarioPage } from '../crearusuario/crearusuario.page';
import { ResetPassTokenPage } from '../reset-pass-token/reset-pass-token.page';
import { RecuperarContraPage } from '../homecliente/modals/recuperar-contra/recuperar-contra.page';






@Component({
  selector: 'app-loguin',
  templateUrl: './loguin.page.html',
  styleUrls: ['./loguin.page.scss'],
})
export class LoguinPage implements OnInit {

  token;

  @Output() messageEvent = new EventEmitter<any>();

  user = {
    "email": '',
    "password": ''
  }

  rep_pass: string;
  razonsocial: any;
  nombreComercial: any;
  email: any;
  pais: any;
  codigosigla: any;
  escalera: any;
  numero: any;
  piso: any;
  puerta: any;
  CIFDNI: any;
  telefono: any;
  letra: any;
  codpostal: any;
  calle: any;
  poblacion: any;
  provincia: any;
  evalue: any[];
  datos: Object;
  id: any;
  modelData: any;
  Nombre: any;
  Apellidos: any;
  Permiso: any;
  ceco: any;


  constructor(
    private loginService: LoginService,
    private toastController: ToastController,
    public navCtrl: NavController,
    private router: Router,
    private route: ActivatedRoute,
    private storage: Storage,
    public loadingCtrl: LoadingController,
    // public modalCtrl : ModalController,
    public modalController: ModalController,
  ) {

  }

  async ngOnInit() {

    await this.storage.create();
    await this.storage.clear();
    this.route.queryParams.subscribe((params: any) => {
      if (params["m"]) {
        // console.log("TEST", params);
        switch (params["m"]) {
          case "reg_prod":
            this.crearusuarionuevo();
            break;
          default:
            break;
        }
      }
    });

  }


  async ionViewWillEnter() {






  }





  async onSubmit(formulario: NgForm) {


    // console.log(formulario.value);


    this.loginService.loginUser(this.user).subscribe(async (ans) => {   // EL ORIGINAL

      // console.log(ans);
      this.datos = ans;

      if (ans == 'error') {


        const toast = await this.toastController.create({
          message: "Error en el loguin, usuario o contraseña erroneos, intentelo otra vez",
          duration: 4000,
          position: 'middle'
        });
        toast.present();
        await this.storage.set('insesion', 'no');

        //this.navCtrl.navigateRoot('/home');

      } else {

        const loading = await this.loadingCtrl.create({
          spinner: 'dots',
          message: 'Cargando espera unos segundos...',
          duration: 1000,
        });

        await loading.present();

        this.id = ans[0]['cid'];
        this.razonsocial = ans[0]['RazonSocial'];
        this.nombreComercial = ans[0]['NombreComercial'];
        this.CIFDNI = ans[0]['CIFDNI'];
        this.telefono = ans[0]['Telefono'];
        this.email = ans[0]['Email'];
        this.pais = ans[0]['Pais'];
        this.codigosigla = ans[0]['CodigoSigla'];
        this.escalera = ans[0]['Escalera'];
        this.numero = ans[0]['Numero'];
        this.piso = ans[0]['Piso'];
        this.puerta = ans[0]['Puerta'];
        this.letra = ans[0]['Letra'];
        this.codpostal = ans[0]['CodPostal'];
        this.calle = ans[0]['Calle'];
        this.poblacion = ans[0]['Poblacion'];
        this.provincia = ans[0]['Provincia'];
        this.Nombre = ans[0]['Nombre'];
        this.Apellidos = ans[0]['Apellidos'];
        this.Permiso = ans[0]['Permiso'];
        this.ceco = ans[0]['ceco'];


        await this.storage.set('insesion', 'si');
        await this.storage.set('razonsocial', this.razonsocial);
        await this.storage.set('NombreComercial', this.nombreComercial);
        await this.storage.set('id', this.id);
        await this.storage.set('CIFDNI', this.CIFDNI);
        await this.storage.set('telefono', this.telefono);
        await this.storage.set('email', this.email);
        await this.storage.set('pais', this.pais);
        await this.storage.set('codigosigla', this.codigosigla);
        await this.storage.set('escalera', this.escalera);
        await this.storage.set('numero', this.numero);
        await this.storage.set('piso', this.piso);
        await this.storage.set('puerta', this.puerta);
        await this.storage.set('letra', this.letra);
        await this.storage.set('codpostal', this.codpostal);
        await this.storage.set('calle', this.calle);
        await this.storage.set('poblacion', this.poblacion);
        await this.storage.set('provincia', this.provincia);
        await this.storage.set('Nombre', this.Nombre);
        await this.storage.set('Apellidos', this.Apellidos);
        await this.storage.set('Permiso', this.Permiso);
        await this.storage.set('ceco', this.ceco);

        // this.messageEvent.emit(this.datos);

        // console.log("this.permiso"+this.Permiso);


        if (this.Permiso === 'administrador' || this.Permiso === 'disenyador' || this.ceco === 'SANITAS') {
          //console.log("this.permiso"+this.Permiso);
          // console.log("this.ceco" + JSON.stringify(ans));
          this.navCtrl.navigateRoot('/dashboard', { queryParams: ans });
        } else {
          //console.log("this.permiso1"+this.Permiso);
          // console.log("this.ceco" + JSON.stringify(ans));
          this.navCtrl.navigateRoot('/homecliente', { queryParams: ans });
        }
      }
    });
  }




  async crearusuarionuevo() {
    const modal = await this.modalController.create({
      component: CrearusuarioPage,
      cssClass: "wideModal",
      componentProps: {
        'model_title': "Crear usuario nuevo"
      }
    });

    modal.onDidDismiss().then((modelData) => {
      if (modelData !== null) {
        this.modelData = modelData.data;
        // console.log('Modal Data : ' + modelData.data);
      }
    });

    return await modal.present();
  }

  async recuperarContra() {
    const modal = await this.modalController.create({
      component: RecuperarContraPage,
      cssClass: 'olvidarPassword',
      componentProps: {
        'token': this.token,
      }
    });
    await modal.present();

    // const {data} = await modal.onDidDismiss(); SE EJECUTA CUANDO TERMINA LA ANIMACION
    await modal.onWillDismiss(); // SE EJECUTA AL HACER CLICK EN CERRAR(ANTES QUE LA ANIMACION)
  }
}
