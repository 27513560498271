import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RutasService {

  BASE_RUTA = "https://www.whiberica.es/APIWH/rest/index.php/";
  BASE_RUTASQL = "https://www.whiberica.es/APIWH/";


  // BASE_RUTA = "https://www.whiberica.es/APIWH-TEST/rest/index.php/";
  // BASE_RUTASQL = "https://www.whiberica.es/APIWH-TEST/";


  constructor() { }
}
