import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/servicios/storage.service';

import { Todo } from '../../../interface/usuario';
import { ProductosService } from 'src/app/servicios/productos.service';
import * as $ from "jquery";
import { ModalController, NavController, ToastController, AlertController, LoadingController, NavParams } from '@ionic/angular';
import { IonicSelectableComponent } from 'ionic-selectable';


@Component({
  selector: 'app-editarproducto',
  templateUrl: './editarproducto.page.html',
  styleUrls: ['./editarproducto.page.scss'],
})
export class EditarproductoPage implements OnInit {

  respuesta: any;
  copiaArrayArchivos: any[];
  descripcionArticulo: any;
  datos: Todo[];
  cif: any;
  fecha: any;
  numeroserie: any;
  codigoArticulo: any;
  port = "Referencia *"
  RazonSocial: any;
  Email: any;
  page: number;
  busqueda_actual: any;
  fecha1: any;
  date: any;
  referencia: any;
  CodigoArticulo: any;
  f = {
    "name": ""
  }
  id: any;
  ceco: any;
  tipo: string = ''; // Inicializa la variable para capturar el valor seleccionado

  constructor(
    private alertController: AlertController,
    private toastController: ToastController,
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public modalController: ModalController,
    private _storage: StorageService,
    private _misproductos: ProductosService,
    public loadingCtrl: LoadingController,
    public navParams: NavParams

  ) { }

  ngOnInit() {
  }

  async ionViewWillEnter() {


    this.datos = this.navParams.get('datos');
    this.numeroserie = this.datos['NumeroSerie'];
    this.CodigoArticulo = this.datos['Referencia'];
    this.descripcionArticulo = this.datos['DescripcionArticulo'];
    this.f['name'] = this.datos['factura'];
    this.id = this.datos['id'];
    this.tipo = this.datos['tipo'];


    this.fecha = new Date("16-01-2019");

    this.fecha = this.datos['fecha'];

    // console.log("this.fecha"+this.fecha);
    // console.log("this.datos"+JSON.stringify(this.datos));
    // console.log("this.datos"+JSON.stringify(this.f['name']));
    console.log("this.datos", this.datos);
    console.log("this.tipo", this.tipo);




    // console.log("this.navParams"+this.navParams.get('datos'));

    await this.listararticulos();

    this._storage.read().then((result) => {

      this.datos = result;

      this.copiaArrayArchivos = [];
      this.copiaArrayArchivos.push(... this.datos);


      this.cif = this.copiaArrayArchivos[0]['CIFDNI'];
      this.RazonSocial = this.copiaArrayArchivos[0]['RazonSocial'];
      this.Email = this.copiaArrayArchivos[0]['Email'];

    });


  }




  portChange(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    // console.log('port:', event.value.DescripcionArticulo);
    this.descripcionArticulo = event.value.DescripcionArticulo;
    this.CodigoArticulo = event.value.CodigoArticulo;
  }








  async listararticulos() {


    await this._misproductos.listararticuloscliente().subscribe((ans: []) => {


      this.respuesta = ans;


      this.copiaArrayArchivos = [];
      this.copiaArrayArchivos.push(... this.respuesta);

      // console.log("listar articulos" + JSON.stringify(this.respuesta));


    });


  }








  files: File[] = [];

  onSelect(event) {
    // console.log(event.addedFiles);
    this.files.push(...event.addedFiles);

    // console.log("this files en onselect" + JSON.stringify(this.files));


  }

  onRemove(event) {
    // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
    // console.log("this files en onselect" + JSON.stringify(this.files));
  }


  // Evento que se ejecuta al cambiar el valor
  onTipoChange(event: any) {
    console.log('Tipo seleccionado:', this.tipo); // Aquí puedes ejecutar lógica adicional
    // O accede directamente al valor desde event.detail.value
    console.log('Valor desde el evento:', event.detail.value);
  }


  async altanuevoproducto(dat) {

    //console.log(dat.controls);



    // console.log("dat.controls.descripcionArticulo"+dat.controls.descripcionArticulo.value);
    // console.log("dat.controls.fecha"+dat.controls.fecha.value);
    // console.log("dat.controls.nserie"+dat.controls.nserie.value);
    // console.log("this.files"+JSON.stringify(this.files));
    // console.log("this.files"+JSON.stringify(this.RazonSocial));


    this.descripcionArticulo = dat.controls.descripcionArticulo.value;
    this.fecha = dat.controls.fecha.value;
    this.numeroserie = dat.controls.numeroserie.value;

    // console.log("this.descripcionArticulo"+this.descripcionArticulo);
    // console.log("this.fecha"+this.fecha);
    // console.log("this.descripcionArticulo"+this.descripcionArticulo);


    let articulo = {
      "id": this.id,
      "codigoArticulo": this.CodigoArticulo,
      "fecha": this.fecha,
      "numeroserie": this.numeroserie,
      "CIFDNI": this.cif,
      "RazonSocial": this.RazonSocial,
      "Email": this.Email,
      "tipo": this.tipo
    }

    //console.log(articulo);




    // if (typeof this.descripcionArticulo === 'undefined' || typeof this.fecha === 'undefined' || typeof this.numeroserie == 'undefined' ) {

    //   //console.log("DENTROOO DEL TYPEOFF");

    //   const alert = await this.alertController.create({
    //     cssClass: 'my-custom-class',
    //     header: 'Aviso faltan campos por rellenar',
    //     subHeader: '',
    //     message: "Revise todos los campos obligatorios",
    //     buttons: ['OK']
    //   });

    //   await alert.present();
    //   return;
    // }



    if (!this.files.length) {
      // está vacíoa


      this.files = File[""];

    }




    const loading = await this.loadingCtrl.create({
      spinner: 'dots',
      message: 'Cargando espera unos segundos...',
      duration: 5000,
    });

    await loading.present();


    this._misproductos.EditarArticulosNuevos(this.files, articulo).subscribe(async (ans: []) => {

      this.respuesta = ans;

      // console.log("this.respuesta"+ JSON.stringify(this.respuesta));


      if (this.respuesta == 'error') {


        const alert = await this.alertController.create({
          cssClass: 'my-custom-class',
          header: 'Aviso',
          subHeader: '',
          message: this.respuesta['texto'],
          buttons: ['OK']
        });

        await alert.present();

        const { role } = await alert.onDidDismiss();
        // console.log('onDidDismiss resolved with role', role);


      } else {

        const alert = await this.alertController.create({
          cssClass: 'my-custom-class',
          header: 'Aviso',
          subHeader: '',
          message: this.respuesta['texto'],
          buttons: ['OK']
        });

        await alert.present();

        const { role } = await alert.onDidDismiss();
        console.log('onDidDismiss resolved with role', role);



      }


      this.modalCtrl.dismiss();
      // this.navCtrl.navigateRoot('/home');
      // window.location.reload();
      this.ionViewWillEnter();




    });


  }


  closemodal() {

    this.modalCtrl.dismiss();
  }


}
