import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavController, AlertController } from '@ionic/angular';
import { MicuentaService } from 'src/app/servicios/micuenta.service';
import { CreardireccionenesPage } from '../creardireccionenes/creardireccionenes.page';
import { Todo } from '../../../interface/usuario';
import { NuevoproductoPage } from '../nuevoproducto/nuevoproducto.page';
import { AsistenciatecnicaPage } from '../asistenciatecnica/asistenciatecnica.page';
import { HistorialasistenciaPage } from '../historialasistencia/historialasistencia.page';
import { HistorialfacturasPage } from '../historialfacturas/historialfacturas.page';
import { StorageService } from 'src/app/servicios/storage.service';




@Component({
  selector: 'app-menuizquierda',
  templateUrl: './menuizquierda.page.html',
  styleUrls: ['./menuizquierda.page.scss'],
})
export class MenuizquierdaPage implements OnInit {

  @Input()
  menuasistencia: any;

  @Input()
  menuproductos: any;

  @Input()
  menuhistorialfacturas: any;

  @Input()
  menuhistorialasistenciatecnica: any;


  datos: Todo[] = [];
  copiaArrayArchivos: Todo[] = [];
  modelData: any;
  expression: boolean;
  expression1: boolean;

  constructor(
    public modalCtrl: ModalController,
    public modalController: ModalController,
    private _micuenta: MicuentaService,
    public alertCotroller: AlertController,
    public navCtrl: NavController,
    private _storage: StorageService
  ) { }

  ngOnInit() {

    //console.log("menuproductos"+ this.menuproductos);
    //console.log("menuasistencia"+ this.menuasistencia);
  }




  async nuevoproducto() {

    const modal = await this.modalController.create({
      component: NuevoproductoPage,
      componentProps: {
        'model_title': "Nuevo producto"
      }
    });

    modal.onDidDismiss().then((modelData) => {
      if (modelData !== null) {
        this.modelData = modelData;
        // console.log("t", modelData);
        // console.log('Modal Data : ', this.modelData);

        if (this.modelData.data == "ok") {
          window.location.assign('/homecliente');
        }
      }
    });
    // this.navCtrl.navigateForward('/homecliente');
    return await modal.present();

  }



  async misproductos() {
    // console.log("ir a mis productos");
    this.navCtrl.navigateForward('/homecliente');
  }

  async historialfacturass() {
    this.navCtrl.navigateForward('/historialfacturas');
  }

  async historialasistenciatecnica() {
    this.navCtrl.navigateForward('/historialasistencia');
  }

  async solicitarasistencia() {
    // console.log("solicitar asistencia");
    this.navCtrl.navigateForward('/asistenciatecnica');


    // const modal = await this.modalController.create({
    //   component: AsistenciatecnicaPage,
    //   componentProps: {
    //     'model_title': "Crear dirección envío"
    //   }
    // });

    // modal.onDidDismiss().then((modelData) => {
    //   if (modelData !== null) {
    //     this.modelData = modelData;
    //     console.log('Modal Data : ',this.modelData );
    //   }
    // });

    // return await modal.present();


  }




  async historialasistencia() {
    // console.log("historialasistencia");

    const modal = await this.modalController.create({
      component: HistorialasistenciaPage,
      componentProps: {
        'model_title': "Crear dirección envío"
      }
    });

    modal.onDidDismiss().then((modelData) => {
      if (modelData !== null) {
        this.modelData = modelData;
        // console.log('Modal Data : ',this.modelData );
      }
    });

    return await modal.present();


  }



  async historialfacturas() {
    // console.log("historial facturas");
    const modal = await this.modalController.create({
      component: HistorialfacturasPage,
      componentProps: {
        'model_title': "Crear dirección envío"
      }
    });

    modal.onDidDismiss().then((modelData) => {
      if (modelData !== null) {
        this.modelData = modelData;
        // console.log('Modal Data : ',this.modelData );
      }
    });

    return await modal.present();


  }

  AbrirCalendarioLaboral() {

    window.open('https://whiberica.es/APIWH/pdf/CalendarioLaboralW&H.pdf', '_blank');

  }


}
