import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LoginService } from '../../../../servicios/login.service';
import { ModalController, LoadingController, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-recuperar-contra',
  templateUrl: './recuperar-contra.page.html',
  styleUrls: ['./recuperar-contra.page.scss'],
})
export class RecuperarContraPage implements OnInit {

  loading: HTMLIonLoadingElement;
  toast: HTMLIonToastElement;


  constructor(
    private loginService: LoginService,
    private modalController: ModalController,
    private loadingController: LoadingController,
    public toastController: ToastController
  ) { }

  ngOnInit() {
  }

  salir() {
    this.modalController.dismiss();
  }

  user = {
    "email": ''
  }

  closemodal() {
    this.modalController.dismiss();
  }

  async crearToast( mensaje ) {
    this.toast = await this.toastController.create({
      message: mensaje,
      duration: 2000
    });
    this.toast.present();
  }

  async crearLoading( mensaje ) {
    this.loading = await this.loadingController.create({
      // cssClass: 'my-custom-class',
      message: mensaje,
      duration: 2000,
      backdropDismiss: false
    });
    this.loading.present();
  }

  async onSubmit( formulario: NgForm) {
    await this.crearLoading("Generando token de recuperación");
    await this.loginService.generateToken(this.user).subscribe( async (ans:any[]) => {
      await setTimeout(() => {
        this.loading.dismiss();
        console.log("Generando token de recuperación");

      }, 250);
      if (ans['err']) {
        await this.crearToast(ans['message']);
      } else {
        this.crearLoading("Enviando correo...");
        this.user['token'] = ans['datos'];
        this.loginService.resetPassword(this.user).subscribe( async (ans2:any[]) => {

          if (ans['err'] == false ) {
            this.crearToast(ans2['message']);
            this.loading.dismiss();
          }else{
            this.crearToast(ans2['message']);
            this.loading.dismiss();
          }

          await this.closemodal();
        });
      }
    });


  }

}
