import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/servicios/storage.service';

import { Todo } from '../../../interface/usuario';
import { ProductosService } from 'src/app/servicios/productos.service';
import * as $ from "jquery";
import { ModalController, NavController, ToastController, AlertController, LoadingController } from '@ionic/angular';
import { IonicSelectableComponent } from 'ionic-selectable';


@Component({
  selector: 'app-nuevoproducto',
  templateUrl: './nuevoproducto.page.html',
  styleUrls: ['./nuevoproducto.page.scss'],
})
export class NuevoproductoPage implements OnInit {
  respuesta: any;
  copiaArrayArchivos: any[];
  descripcionArticulo: any;
  datos: Todo[];
  cif: any;
  fecha: any;
  asistencia: boolean = false;
  garantia: boolean = false;
  numeroserie: any;
  nserie: any;
  codigoArticulo: any;
  port = "Referencia *"
  RazonSocial: any;
  Email: any;
  page: number;
  busqueda_actual: any;
  textobuscar: any;
  queryText: any;

  constructor(
    private alertController: AlertController,
    private toastController: ToastController,
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public modalController: ModalController,
    private _storage: StorageService,
    private _misproductos: ProductosService,
    public loadingCtrl: LoadingController,

  ) { }

  ngOnInit() {




  }


  async ionViewWillEnter() {

    await this.listararticulos();

    this._storage.read().then((result) => {

      this.datos = result;

      this.copiaArrayArchivos = [];
      this.copiaArrayArchivos.push(... this.datos);


      this.cif = this.copiaArrayArchivos[0]['CIFDNI'];
      this.RazonSocial = this.copiaArrayArchivos[0]['RazonSocial'];
      this.Email = this.copiaArrayArchivos[0]['Email'];


    });

  }




  portChange(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    //console.log('port:', event.value.DescripcionArticulo);
    this.descripcionArticulo = event.value.DescripcionArticulo;
    this.codigoArticulo = event.value.CodigoArticulo;
  }


  selectproducto(event) {

    //console.log(event.target.checked);
    this.asistencia = event.target.checked;
    this.fecha = null;

  }




  async listararticulos() {


    await this._misproductos.listararticuloscliente().subscribe((ans: []) => {


      this.respuesta = ans;


      this.copiaArrayArchivos = [];
      this.copiaArrayArchivos.push(... this.respuesta);

      // console.log("listar articulos" + JSON.stringify(this.respuesta));


    });


  }


  buscartexto(event: {
    component: IonicSelectableComponent,
    text: string
  }) {

    // console.log(event.detail.value);

    // console.log(event.text);
    this.textobuscar = event.text;
    this.respuesta = this.copiaArrayArchivos;





    if (this.textobuscar && this.textobuscar.trim() != '') {
      this.respuesta = this.copiaArrayArchivos.filter((data: any) => {
        var searchedText = data['CodigoArticulo'] + data['DescripcionArticulo'];
        return (searchedText.toLowerCase().indexOf(this.textobuscar.toLowerCase()) > -1);
      });
    }

  }








  files: File[] = [];

  onSelect(event) {
    // console.log(event.addedFiles);
    this.files.push(...event.addedFiles);

    // console.log("this files en onselect" + JSON.stringify(this.files));


  }

  onRemove(event) {
    // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
    // console.log("this files en onselect" + JSON.stringify(this.files));
  }


  async altanuevoproducto(dat) {


    // console.log("dat.controls.asistencia" + JSON.stringify(dat));
    // console.log("dat.controls.fecha"+dat.controls.fecha.value);
    // console.log("dat.controls.nserie"+dat.controls.nserie.value);
    // console.log("this.files"+JSON.stringify(this.files));
    // console.log("this.files"+JSON.stringify(this.RazonSocial));


    this.descripcionArticulo = dat.controls.descripcionArticulo.value;

    if (this.fecha == null) {
      this.fecha = null;
      //console.log("si");

    } else {
      this.fecha = dat.controls.fecha.value;
      //console.log("no");

    }

    this.asistencia = dat.controls.asistencia.value;
    this.garantia = dat.controls.garantia.value;


    // console.log("this.descripcionArticulo" + this.descripcionArticulo);
    // console.log("this.fecha" + this.fecha);
    // console.log("this.numeroserie" + this.numeroserie);
    // console.log("this.asistencia" + this.asistencia);
    // console.log("this.garantia" + this.garantia);



    let articulo = {
      "codigoArticulo": this.codigoArticulo,
      "fecha": this.fecha,
      "numeroserie": dat.controls.numeroserie.value,
      "CIFDNI": this.cif,
      "RazonSocial": this.RazonSocial,
      "Email": this.Email,
      "asistencia": this.asistencia,
      "garantia": this.garantia
    }

    //console.log("this.articulo", articulo);

    if (this.fecha === null) {

      // console.log(this.fecha);


      if (this.asistencia === false && (this.garantia === false || this.garantia === true)) {

        // console.log(this.asistencia);
        // console.log(this.garantia);


        const alert = await this.alertController.create({
          cssClass: 'my-custom-class',
          header: 'Aviso faltan campos por rellenar',
          subHeader: '',
          message: "Revise los campos obligatorios",
          buttons: ['OK']
        });

        await alert.present();
        return;

      }




    }


    if (typeof this.descripcionArticulo === 'undefined' || typeof this.numeroserie == 'undefined' || this.numeroserie == '') {

      //console.log("DENTROOO DEL TYPEOFF");

      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Aviso faltan campos por rellenar',
        subHeader: '',
        message: "Revise todos los campos obligatorios",
        buttons: ['OK']
      });

      await alert.present();
      return;
    }




    // if (!this.files.length) {
    //   // está vacíoa

    //   const alert = await this.alertController.create({
    //     cssClass: 'my-custom-class',
    //     header: 'Aviso falta fichero',
    //     subHeader: '',
    //     message: "Tiene que subir la factura",
    //     buttons: ['OK']
    //   });

    //   await alert.present();
    //   return;


    // }


    const loading = await this.loadingCtrl.create({
      spinner: 'dots',
      message: 'Espere unos segundos...',
      // duration: 5000

    });

    await loading.present();

    // return; ///quitarrrrrrrrrÇ

    this._misproductos.crearArticulosNuevos(this.files, articulo).subscribe(async (ans: []) => {

      this.respuesta = ans;

      // console.log("this.respuesta"+ JSON.stringify(this.respuesta));


      if (this.respuesta == 'error') {

        this.loadingCtrl.dismiss();


        const alert = await this.alertController.create({
          cssClass: 'my-custom-class',
          header: 'Aviso',
          subHeader: '',
          message: this.respuesta['texto'],
          buttons: [{
            text: 'OK',
            handler: () => {
              this.alertController.dismiss("ok");
            }
          }],
        });

        await alert.present();

        const { role } = await alert.onDidDismiss();
        // console.log('onDidDismiss resolved with role', role);


      } else {

        this.loadingCtrl.dismiss();

        const alert = await this.alertController.create({
          cssClass: 'my-custom-class',
          header: 'Aviso',
          subHeader: '',
          message: this.respuesta['texto'],
          buttons: [{
            text: 'OK',
            handler: () => {
              this.alertController.dismiss("ok");
            }
          }],
        });

        await alert.present();


        await alert.onDidDismiss();





      }


      this.modalCtrl.dismiss("ok");

      // this.navCtrl.navigateForward('/homecliente');
      this.ionViewWillEnter();




    });



  }




  closemodal() {

    this.modalCtrl.dismiss();
  }





}
