import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateOnly'
})
export class DateOnlyPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;
    const [year, month, day] = value.split(' ')[0].split('-');
    return `${day}-${month}-${year}`; // Format as DD-MM-YYYY
  }

}
