import { Component, OnInit, Input } from '@angular/core';
import { CrudTablasService } from 'src/app/servicios/crud-tablas.service';
import { AlertController, ModalController } from '@ionic/angular';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-modal-pdf',
  templateUrl: './modal-pdf.page.html',
  styleUrls: ['./modal-pdf.page.scss'],
})
export class ModalPdfPage implements OnInit {

  @Input() CIFDNI: string = "";
  @Input() Referencia: string = "";
  @Input() texto: string = "";
  @Input() id;
  @Input() old_dir: string = "";

  file: File[] = [];
  pdf: string = "";

  constructor(  private crudTabla: CrudTablasService,
                public alertController: AlertController,
                public modalController : ModalController) { }

  ngOnInit() {
  }


  async onSubmit( formulario: NgForm) {
    this.crudTabla.cambiarFacturaArtGar( this.CIFDNI, this.Referencia, this.id, this.file[0], this.old_dir ).subscribe( (ans: any) => {
      this.presentAlert(ans['message']);
    });
    // console.log("CIFDNI", this.CIFDNI);
    // console.log("Referencia", this.Referencia);
    // console.log("ID", this.id);
    this.modalController.dismiss(
      "articulos_garantia"
    );
  }

  async presentAlert( mensaje) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Información',
      // subHeader: 'Subtitle',
      message: mensaje,
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    // console.log('onDidDismiss resolved with role', role);
  }

  onSelect(event) {
    this.file = event.addedFiles;
    console.log(this.file[0].name);
    this.pdf= this.file[0].name;
  }

}
