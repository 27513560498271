import { Component, OnInit } from '@angular/core';

import { CrudTablasService } from '../../servicios/crud-tablas.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ModalController, AlertController, LoadingController } from '@ionic/angular';
import { ModalAddPage } from '../modal-add/modal-add.page';
import { StorageService } from '../../servicios/storage.service';
import { ModalImagePage } from '../modal-image/modal-image.page';
import { ModalPdfPage } from '../modal-pdf/modal-pdf.page';


@Component({
  selector: 'app-crud',
  templateUrl: './crud.page.html',
  styleUrls: ['./crud.page.scss'],
})
export class CrudPage implements OnInit {

  actualizar: boolean = false;
  loading: HTMLIonLoadingElement;
  cerrarLoading: boolean = false;


  tipo_usuario: any = "";

  dtOptions: any = {};
  Cargando: boolean = true;

  ArrayDataTable: any[];

  TablaActual: string = "";

  constructor(
    private crudTabla: CrudTablasService,
    private loadingController: LoadingController,
    private _storage: StorageService,
    public alertController: AlertController,
    public modalController: ModalController

  ) { }

  async ngOnInit() {
    await this._storage.validarpermisos().then((result) => {
      this.tipo_usuario = result;
      this.TablaActual = (this.tipo_usuario === "administrador") ? "paises" : "sliders";
      this.tablaCambio(this.TablaActual);
    });
  }

  // async dismissLoading() {
  //   console.log("HOLA");
  //   this.loading.dismiss();
  // }

  async tablaCambio(tabla) {
    // this.loading = await this.loadingController.create({
    //   // cssClass: 'my-custom-class',
    //   message: "Cargando tabla",
    //   // duration: 2000
    //   backdropDismiss: this.cerrarLoading
    // });
    // await this.loading.present();
    this.Cargando = true;
    this.dtOptions = {}
    this.ArrayDataTable = [];
    //console.log('Segment changed', tabla);
    this.TablaActual = tabla;
    if (tabla != "") {
      this.crudTabla.getTabla(tabla).subscribe(ans => {
        //console.log(ans);
        this.ArrayDataTable = ans['tabla'];
        this.dtOptions = {
          // columns:[{ "searchable": true },null,null,null,null],
          // columnDefs: [ {
          //   "targets": [0],
          //   // "searchable": false,
          //   "ordering": [false],
          // }],
          ordering: false,
          // order: [0,1,2,3,4],
          // ajax: 'data/data.json',
          // Declare the use of the extension in the dom parameter
          dom: 'Bfrtip',
          // Configure the buttons
          buttons: [
            // 'columnsToggle',
            // 'colvis',
            'csv',
            // 'copy',
            'print',
            'excel',
          ]

        };
        this.Cargando = false;
        // console.log(this.ArrayDataTable);
        // this.loading.dismiss();
      });
      //console.log("PRUEBA");
      // this.loading.dismiss();
    }
    //console.log("PRUEBA2");
    // this.loading.dismiss();
  }

  cambiarActivo(id, activo) {
    // console.log("test", id+"-"+activo);
    this.crudTabla.cambiarEstadoSlider(id, activo).subscribe((ans: any) => {
      this.tablaCambio("");
      this.tablaCambio("sliders");
    });
  }

  cambiarTabla(ev: any) {
    this.tablaCambio(ev.target.value);
  }

  actualizarTabla() {
    let tabla = this.TablaActual
    this.actualizar = false;
    this.tablaCambio("");
    this.tablaCambio(tabla);
  }

  agregar(tabla, texto) {
    this.abrirModal({ "tabla": tabla, "accion": "add", "texto": texto });
  }

  edit(tabla, texto, data, pos?) {
    this.abrirModal({ "tabla": tabla, "accion": "edit", "texto": texto, "data": data }, pos);
  }

  async delete(id, tabla, factura_path?) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Borrar fila con ID: ' + id,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Borrar',
          handler: () => {
            if (factura_path) {
              this.crudTabla.deleteInDatabase({ "id": id, "tabla": tabla }, factura_path).subscribe(ans => {
                this.presentAlert(ans['message']);
                this.tablaCambio("");
                this.tablaCambio(tabla);
              });
            } else {
              this.crudTabla.deleteInDatabase({ "id": id, "tabla": tabla }).subscribe(ans => {
                this.presentAlert(ans['message']);
                this.tablaCambio("");
                this.tablaCambio(tabla);
              });
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async presentAlert(mensaje) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Información',
      // subHeader: 'Subtitle',
      message: mensaje,
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    //console.log('onDidDismiss resolved with role', role);
  }

  async abrirModal(datos, pos?) {
    //console.log("prueba", datos);
    console.log("ENTRO");
    const modal = await this.modalController.create({
      component: ModalAddPage,
      componentProps: { // ESTO PASA ARGUMENTOS
        datos: datos,
        posicion: (pos || pos == 0) ? pos : -1,
      }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.actualizar = true;
      // let datos = JSON.parse(data);
      // console.log( datos.datos  );
      // console.log( this.ArrayDataTable[datos.posicion]  );
      // this.ArrayDataTable[datos.posicion] = datos.datos;
      // console.log("ACTUALIZADO",  this.ArrayDataTable[datos.posicion]);
      // this.tablaCambio("");
      // this.tablaCambio(data)
    };
  }

  async abrirModalImagen(tabla, texto, datos, ruta) {
    console.log("prueba", datos);
    const modal = await this.modalController.create({
      component: ModalImagePage,
      componentProps: { // ESTO PASA ARGUMENTOS
        tabla: tabla,
        texto: texto,
        datos: datos,
        ruta: ruta
      }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) { this.actualizarTabla() };
  }

  async abrirModalPDF(CIFDNI, Referencia, texto, id, old_dir) {
    const modal = await this.modalController.create({
      component: ModalPdfPage,
      componentProps: { // ESTO PASA ARGUMENTOS
        CIFDNI: CIFDNI,
        Referencia: Referencia,
        texto: texto,
        id: id,
        old_dir: old_dir
      }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) { this.tablaCambio(""); this.tablaCambio(data) };
  }


  generarRutaPDF(fin_ruta) {
    return "https://www.whiberica.es/APIWH/" + fin_ruta;
  }

  generarRutaPdfFormacion(id, fin_ruta) {
    return "https://www.whiberica.es/APIWH/pdf/formaciones/" + id + "/" + fin_ruta;
  }
  generarRutaPdfPrestamo(id, fin_ruta) {
    return "https://www.whiberica.es/APIWH/pdf/prestamos/" + id + "/" + fin_ruta;
  }

  generarRutaPdfPedidos(id, fin_ruta) {
    return "https://www.whiberica.es/APIWH/pdf/pedidos/" + id + "/" + fin_ruta;
  }

  generarRutaPdfReparaciones(id, fin_ruta) {
    return "https://www.whiberica.es/APIWH/pdf/reparaciones/" + id + "/" + fin_ruta;
  }

  generarRutaPDFAsistencia(fin_ruta) {
    return "https://www.whiberica.es/APIWH/pdf/asistencia/" + fin_ruta;
  }

  prestamoDevuelto(dato) {
    // console.log("dato", dato);
    return (Number(dato)) ? 'Devuelto' : 'Pendiente';
  }
  satisfaccionFormacion(dato) {
    switch (dato) {
      case 'm':
        return 'Muy satisfactorio';
      case 's':
        return 'Satisfactorio';
      case 'p':
        return 'Poco satisfactorio';
      default:
        return 'Pendiente valoración';
    }
  }
}
