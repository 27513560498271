import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';


import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';


import { HttpClientModule } from '@angular/common/http'
import { SharedModule } from './shared/shared.module';
import { FormsModule } from '@angular/forms';


import { AppRoutingModule } from './app-routing.module';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { IonicStorageModule } from '@ionic/storage-angular';
import { FilterPipe } from './pipe/filter.pipe';
import { DateOnlyPipe } from './pipe/date-only.pipe';

// IMPORTANDO SERVICIOS



@NgModule({
  declarations: [AppComponent, FilterPipe, DateOnlyPipe],
  entryComponents: [],
  imports: [BrowserModule, HttpClientModule, IonicModule.forRoot(), FormsModule, AppRoutingModule, IonicStorageModule.forRoot()],
  providers: [
    InAppBrowser,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
    SharedModule
  ]
})
export class AppModule {

}
