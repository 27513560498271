import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, AlertController } from '@ionic/angular';
import { MicuentaService } from 'src/app/servicios/micuenta.service';
import { CreardireccionenesPage } from '../creardireccionenes/creardireccionenes.page';
import { Todo } from '../../../interface/usuario';

@Component({
  selector: 'app-menumicuenta',
  templateUrl: './menumicuenta.page.html',
  styleUrls: ['./menumicuenta.page.scss'],
})
export class MenumicuentaPage implements OnInit {

  respuesta: any = {
    datos: ""
  };

  datos: Todo[] = [];
  copiaArrayArchivos: Todo[] = [];
  modelData: any;


  constructor(
    public modalCtrl: ModalController,
    public modalController: ModalController,
    private _micuenta: MicuentaService,
    public alertCotroller: AlertController,
    public navCtrl: NavController,
  ) {



  }

  ngOnInit() {
  }


  async anadirdireccion() {

    const modal = await this.modalController.create({
      component: CreardireccionenesPage,
      componentProps: {
        'desdemenucuenta': "yes"
      }
    });

    modal.onDidDismiss().then((modelData) => {
      if (modelData !== null) {
        this.modelData = modelData.data;
        // console.log('Modal Data : ', this.modelData);

      }
    });

    await modal.present();

    this.navCtrl.navigateRoot('/micuenta');



  }



}
