import { Component, OnInit } from '@angular/core';
import { UsuarioavanzadoService } from 'src/app/servicios/usuarioavanzado.service';
import { LoadingController } from '@ionic/angular';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

@Component({
  selector: 'app-informes',
  templateUrl: './informes.page.html',
  styleUrls: ['./informes.page.scss'],
})
export class InformesPage implements OnInit {

  termVacausVLC: string = '';
  termVacausProduccion: string = '';
  termVacausReparacion: string = '';
  termVacausTransito: string = '';

  respuesta: any;

  title = "Notificaciones";
  ArrayDataReservaVLC: any[] = [];
  ArrayDataEnReparacion: any[] = [];
  ArrayDataEnTransito: any[] = [];
  ArrayDataEnProduccion: any[] = [];

  listEnProduccion: any[] = [];
  listEnReparacion: any[] = [];
  listPartesSage: any[] = [];
  listEnTransito: any[] = [];

  filteredListEnProduccion: any[] = [];
  filteredListEnReparacion: any[] = [];
  filteredListEnTransito: any[] = [];
  filteredArrayDataReservaVLC: any[] = [];

  currentPageVLC = 1;
  currentPageProduccion = 1;
  currentPageReparacion = 1;
  // currentPageTransito = 1;

  itemsPerPageVLC = 12;
  itemsPerPageProduccion = 15;
  itemsPerPageReparacion = 15;
  // itemsPerPageTransito = 15;


  currentPageTransito: number = 1;
  itemsPerPageTransito: number = 10;
  pagesTransito: number[] = [];

  // Variables para el manejo de bloques de páginas
  pageBlockProduccion: number = 0;
  visiblePagesProduccion: number[] = [];
  maxPageBlockProduccion: number = 0;

  pageBlockVLC: number = 0;
  visiblePagesVLC: number[] = [];
  maxPageBlockVLC: number = 0;

  pageBlockReparacion: number = 0;
  visiblePagesReparacion: number[] = [];
  maxPageBlockReparacion: number = 0;

  pageBlockTransito: number = 0;
  visiblePagesTransito: number[] = [];
  maxPageBlockTransito: number = 0;

  // currentReparacion: 1;


  pagesVLC: number[] = [];
  pagesProduccion: number[] = [];
  pagesReparacion: number[] = [];
  // pagesTransito: number[] = [];

  sortColumn: string = '';
  sortAscending: boolean = true;




  sumatorioArrayDAtaReservaVLC: number = 0;
  sumatorioArraylistEnProduccion: number = 0;
  sumatorioArraylistEnReparacion: number = 0;
  sumatorioArraylistTransito: number = 0;
  sumatorioTotal: number = 0;

  listEnReparacionFiltrada: any[] = [];
  resultadoProduccionReparacion: any[];
  resultadoProduccionentransito: any[];


  listarTransito: any[];
  loading: HTMLIonLoadingElement;
  resultadoEnTransito: any;
  datosPrepExcel: any;
  totalpartesSage: any[];
  resultadoTotalIncidencias: any[];
  listParteIncidencias: any;

  fechaDesde: string | null = null;
  fechaHasta: string | null = null;




  constructor(private usuarioavanzadoService: UsuarioavanzadoService, public loadingCtrl: LoadingController,) {



  }

  async ngOnInit() {
    // console.log('InformesPage');


    const ansreparacion = await this.usuarioavanzadoService.listarEnPartesSage().toPromise();

    this.listPartesSage = ansreparacion as any[];

    this.totalpartesSage = this.listPartesSage;

    const jsonFilteredtotalpartesSage = JSON.stringify(this.listPartesSage);


    // console.log("this.listPartesSage", this.listPartesSage);



    const ansincidencias = await this.usuarioavanzadoService.listarEnInformes(jsonFilteredtotalpartesSage).toPromise();

    this.listEnProduccion = ansincidencias['datos'];

    // console.log("this.listEnProduccion", this.listEnProduccion);


    this.listEnProduccion = this.listEnProduccion.map(item => {
      if (item.FechaParte) {
        const [year, month, day] = item.FechaParte.split(' ')[0].split('-');
        item.FechaParte = `${day}-${month}-${year}`; // Format as DD-MM-YYYY
      }
      if (item.FechaSolicitud) {
        const [year, month, day] = item.FechaSolicitud.split(' ')[0].split('-');
        item.FechaSolicitud = `${day}-${month}-${year}`; // Format as DD-MM-YYYY
      }

      if (item.FechaCierreParte) {
        const [year, month, day] = item.FechaCierreParte.split(' ')[0].split('-');
        item.FechaCierreParte = `${day}-${month}-${year}`; // Format as DD-MM-YYYY
      }
      // console.log("item", item);

      return item;
    });


    this.filteredListEnProduccion = [...this.listEnProduccion];

    // this.filteredListEnProduccion.sort((a: any, b: any) => {
    //   const dateA = a.FechaParte;
    //   const dateB = b.FechaParte;
    //   console.log("dateA", dateA);
    //   console.log("dateB", dateB);

    //   return dateB - dateA;
    // }); // Ordenamos por fecha de parte;


    // Filtrar y ordenar el array por FechaSolicitud de mayor a menor
    this.filteredListEnProduccion = this.filteredListEnProduccion.filter(item => {
      // Aquí puedes agregar condiciones de filtro si es necesario
      return true; // Por ejemplo, devolver true si quieres incluir todos los elementos
    }).sort((a, b) => {
      // Ordenar de mayor a menor FechaSolicitud
      const dateA = moment(a.FechaSolicitud, 'DD-MM-YYYY');
      const dateB = moment(b.FechaSolicitud, 'DD-MM-YYYY');
      return dateB.diff(dateA); // Orden descendente
    });


    // this.filteredListEnProduccion.sort((a, b) => {
    //   // Convertir fechas a objetos Date para la comparación
    //   const fechaSolicitudA = new Date(a['FechaSolicitud']);
    //   const fechaSolicitudB = new Date(b['FechaSolicitud']);
    //   const fechaParteA = new Date(a['FechaParte']);
    //   const fechaParteB = new Date(b['FechaParte']);
    //   const fechaCierreParteA = new Date(a['FechaCierreParte']);
    //   const fechaCierreParteB = new Date(b['FechaCierreParte']);

    //   // Comparación por fecha de solicitud (de más nuevo a más antiguo)
    //   if (fechaSolicitudA > fechaSolicitudB) return -1;
    //   if (fechaSolicitudA < fechaSolicitudB) return 1;

    //   // Comparación por Referencia
    //   if (a['Referencia'] > b['Referencia']) return 1;
    //   if (a['Referencia'] < b['Referencia']) return -1;

    //   // Comparación por Número de serie
    //   if (a['NumeroSerie'] > b['NumeroSerie']) return 1;
    //   if (a['NumeroSerie'] < b['NumeroSerie']) return -1;

    //   // Comparación por fecha de parte (de más nuevo a más antiguo)
    //   if (fechaParteA > fechaParteB) return -1;
    //   if (fechaParteA < fechaParteB) return 1;

    //   // Comparación por fecha de cierre de parte (de más nuevo a más antiguo)
    //   if (fechaCierreParteA > fechaCierreParteB) return -1;
    //   if (fechaCierreParteA < fechaCierreParteB) return 1;

    //   return 0; // Si todas las comparaciones son iguales
    // });

    // console.log("nuevo array", this.filteredListEnProduccion);

    this.ArrayDataEnProduccion = this.filteredListEnProduccion;


    this.updatePagination('Produccion');


    // cssancho@sanitas.es
  }


  convertirAFecha(fechaString: string): Date | null {
    if (!fechaString) {
      return null;
    }
    const partes = fechaString.split('-');
    if (partes.length !== 3) {
      console.error(`Formato de fecha incorrecto: ${fechaString}`);
      return null;
    }
    const dia = parseInt(partes[0]);
    const mes = parseInt(partes[1]) - 1; // Los meses en JavaScript son de 0 a 11
    const año = parseInt(partes[2]);
    return new Date(año, mes, dia);
  }


  updatePagination(set: string) {
    if (set === 'Produccion') {
      this.pagesProduccion = Array(Math.ceil(this.filteredListEnProduccion.length / this.itemsPerPageProduccion)).fill(0).map((x, i) => i + 1);
      if (this.currentPageProduccion > this.pagesProduccion.length) {
        this.currentPageProduccion = this.pagesProduccion.length;
      }
      this.maxPageBlockProduccion = Math.floor((this.pagesProduccion.length - 1) / 10);
      this.updateVisiblePages('Produccion');
    }
  }

  updateVisiblePages(set: string) {
    if (set === 'Produccion') {
      this.visiblePagesProduccion = this.pagesProduccion.slice(this.pageBlockProduccion * 10, (this.pageBlockProduccion + 1) * 10);
    }
  }

  prevPageBlock(set: string) {
    if (set === 'Produccion' && this.pageBlockProduccion > 0) {
      this.pageBlockProduccion--;
      this.updateVisiblePages('Produccion');
    }
  }

  nextPageBlock(set: string) {
    if (set === 'Produccion' && this.pageBlockProduccion < this.maxPageBlockProduccion) {
      this.pageBlockProduccion++;
      this.updateVisiblePages('Produccion');
    }
  }

  prevPage(set: string) {
    // console.log('prevPage', set);

    if (set === 'Produccion' && this.currentPageProduccion > 1) {
      this.currentPageProduccion--;
      if (this.currentPageProduccion <= this.pageBlockProduccion * 10) {
        this.prevPageBlock('Produccion');
      }
    }
  }

  nextPage(set: string) {
    // console.log('nextPage', set);

    if (set === 'Produccion' && this.currentPageProduccion < this.pagesProduccion.length) {
      this.currentPageProduccion++;
      if (this.currentPageProduccion > (this.pageBlockProduccion + 1) * 10) {
        this.nextPageBlock('Produccion');
      }
    }
  }

  goToPage(set: string, page: number) {
    // console.log('goToPage', set, page);

    if (set === 'Produccion') {
      this.currentPageProduccion = page;
    }
  }



  onFechaDesdeChange(value: string) {
    this.fechaDesde = value;

  }

  onFechaHastaChange(value: string) {
    this.fechaHasta = value;

  }


  filtrar() {


    // console.log('this.fechaDesde', this.fechaDesde);
    // console.log('this.fechaHasta', this.fechaHasta);


    if (this.fechaDesde && this.fechaHasta) {
      // Formatear this.fechaDesde y this.fechaHasta al formato MM-DD-YYYY
      const fechaDesdeFormatted = moment(this.fechaDesde, 'YYYY-MM-DD').format('DD-MM-YYYY');
      const fechaHastaFormatted = moment(this.fechaHasta, 'YYYY-MM-DD').format('DD-MM-YYYY');

      // console.log('this.fechaDesdeFormatted', fechaDesdeFormatted);
      // console.log('this.fechaHastaFormatted', fechaHastaFormatted);

      // Realizar filtrado
      this.filteredListEnProduccion = this.ArrayDataEnProduccion.filter(item => {
        const itemDate = moment(item.FechaParte, 'DD-MM-YYYY'); // Asumiendo que item.FechaParte es MM-DD-YYYY
        // console.log('itemDate', itemDate);

        return itemDate.isBetween(moment(fechaDesdeFormatted, 'DD-MM-YYYY'), moment(fechaHastaFormatted, 'DD-MM-YYYY'), null, '[]');
      });

      this.currentPageProduccion = 1;
      this.updatePagination('Produccion');
    } else {
      // Mostrar un mensaje de error o manejar la situación según tus necesidades
      // console.log('Debe seleccionar ambas fechas para filtrar.');
      alert('Debe seleccionar ambas fechas para filtrar.');
      // Aquí podrías mostrar una alerta, mensaje o simplemente no hacer nada hasta que se completen las fechas.
    }
  }



  filterAndPaginateData(set: string) {

    // console.log('set', set);


    if (set === 'Produccion') {
      this.filteredListEnProduccion = this.ArrayDataEnProduccion.filter(item =>
        Object.values(item).some(val => val !== null && val.toString().toLowerCase().includes(this.termVacausProduccion.toLowerCase()))
      );
      this.currentPageProduccion = 1; // Reset page to the first page
      this.updatePagination('Produccion');
    } else if (set === 'Reparacion') {
      this.filteredListEnReparacion = this.ArrayDataEnReparacion.filter(item =>
        Object.values(item).some(val => val !== null && val.toString().toLowerCase().includes(this.termVacausReparacion.toLowerCase()))
      );
      this.currentPageReparacion = 1; // Reset page to the first page
      this.updatePagination('Reparacion');
    } else if (set === 'Transito') {
      this.filteredListEnTransito = this.ArrayDataEnTransito.filter(item =>
        Object.values(item).some(val => val !== null && val.toString().toLowerCase().includes(this.termVacausTransito.toLowerCase()))
      );
      this.currentPageTransito = 1; // Reset page to the first page
      this.updatePagination('Transito');
    }
  }


  async clearDates() {
    await this.ngOnInit();
    this.fechaDesde = null;
    this.fechaHasta = null;
    this.termVacausProduccion = '';
    this.currentPageProduccion = 1;
    this.updatePagination('Produccion');
  }


  sortData(column: string, asNumber: boolean = false, set: string) {
    if (this.sortColumn === column) {
      this.sortAscending = !this.sortAscending;
    } else {
      this.sortColumn = column;
      this.sortAscending = true;
    }

    let dataToSort;

    if (set === 'Produccion') {
      dataToSort = this.filteredListEnProduccion;
    }

    dataToSort.sort((a, b) => {
      let valueA = a[column];
      let valueB = b[column];

      // Si la columna es FechaParte o FechaSolicitud, convertir a objetos de fecha
      if (column === 'FechaParte' || column === 'FechaSolicitud' || column === 'FechaCierreParte') {
        valueA = this.convertirAFecha(valueA);
        valueB = this.convertirAFecha(valueB);
      } else {
        if (asNumber) {
          valueA = parseFloat(valueA);
          valueB = parseFloat(valueB);
        } else {
          if (typeof valueA === 'string') {
            valueA = valueA.toLowerCase();
          }
          if (typeof valueB === 'string') {
            valueB = valueB.toLowerCase();
          }
        }
      }

      if (valueA < valueB) {
        return this.sortAscending ? -1 : 1;
      } else if (valueA > valueB) {
        return this.sortAscending ? 1 : -1;
      } else {
        return 0;
      }
    });
  }



  exportexcel(opt?: any): void {

    // cssancho@sanitas.es

    let title = 'export-json';
    let fileName = 'Export_json.xlsx';
    // console.log('opt ', opt);


    switch (opt) {

      case 'Reparacion':

        this.datosPrepExcel = this.filteredListEnReparacion.map(obj => ({
          'TIPO': obj.tipo,
          'CECO': obj.ceco,
          'CLÍNICA': obj.RazonSocial,
          'REFERENCIA': obj.Referencia,
          'NÚMERO SERIE': obj.NumeroSerie,
          'DESCRIPCIÓN ARTÍCULO': obj.DescripcionArticulo,
          'FECHA SOLICITUD': obj.FechaSolicitud,
          'NÚMERO PARTE': obj.NumeroParteLc,
          'FECHA PARTE': obj.FechaParte,
          'DESCRIPCIÓN ERROR': obj.DescripcionError,
          'FRANQUICIA': obj.Franquicia,
          'FECHA CIERRE PARTE': obj.FechaCierreParte,
          'OBSERVACIONES': obj.WehadentComentarioDSO
        }));


        fileName = 'ExcelInformePartes.xlsx';

        break;

      case 'Produccion':

        this.datosPrepExcel = this.filteredListEnProduccion.map(obj => ({
          'TIPO': obj.ceco,
          'CECO': obj.ceco,
          'CLÍNICA': obj.RazonSocial,
          'REFERENCIA': obj.Referencia,
          'NÚMERO SERIE': obj.NumeroSerie,
          'DESCRIPCIÓN ARTÍCULO': obj.DescripcionArticulo,
          'FECHA SOLICITUD': obj.FechaSolicitud,
          'NÚMERO PARTE': obj.NumeroParteLc,
          'FECHA PARTE': obj.FechaParte,
          'DESCRIPCIÓN ERROR': obj.DescripcionError,
          'FRANQUICIA': obj.Franquicia,
          'FECHA CIERRE PARTE': obj.FechaCierreParte,
          'OBSERVACIONES': obj.WehadentComentarioDSO
        }));

        fileName = 'ExcelInformePartes.xlsx';

        break;

      case 'VLC':

        this.datosPrepExcel = this.filteredArrayDataReservaVLC.map(obj => ({
          'CÓDIGO ARTÍCULO': obj.CodigoArticulo,
          'DESCRIPCIÓN ARTÍCULO': obj.DescripcionArticulo,
          'UNIDADES': obj.UnidadSaldo,
        }));

        fileName = 'ExcelStockUrgencia.xlsx';

        break;

      case 'Transito':

        this.datosPrepExcel = this.filteredListEnTransito.map(obj => ({
          'CÓDIGO ARTÍCULO': obj.CodigoArticulo,
          'DESCRIPCIÓN ARTÍCULO': obj.DescripcionArticulo,
          'UNIDADES': obj.UnidadSaldo,
        }));

        fileName = 'ExcelTransito.xlsx';

        break;


    }


    // console.log('this.datosPrepExcel ', this.datosPrepExcel);

    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.datosPrepExcel);
    // const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataFromDatabase);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, title);
    /* save to file */
    XLSX.writeFile(wb, fileName);

  }








}
