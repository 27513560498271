import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { InformesPageModule } from './ADMIN/informes/informes.module';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./shared/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'crearusuario',
    loadChildren: () => import('./home/crearusuario/crearusuario.module').then(m => m.CrearusuarioPageModule)
  },
  {
    path: 'asistenciatecnica',
    loadChildren: () => import('./home/homecliente/asistenciatecnica/asistenciatecnica.module').then(m => m.AsistenciatecnicaPageModule)
  },
  {
    path: 'micuenta',
    loadChildren: () => import('./home/homecliente/micuenta/micuenta.module').then(m => m.MicuentaPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./ADMIN/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'modal-add',
    loadChildren: () => import('./ADMIN/modal-add/modal-add.module').then(m => m.ModalAddPageModule)
  },
  {
    path: 'crud',
    loadChildren: () => import('./ADMIN/crud/crud.module').then(m => m.CrudPageModule)
  },
  {
    path: 'sanitas',
    loadChildren: () => import('./ADMIN/sanitas/sanitas.module').then(m => m.SANITASPageModule)
  },
  {
    path: 'informes',
    loadChildren: () => import('./ADMIN/informes/informes.module').then(m => m.InformesPageModule)
  },
  {
    path: 'shop',
    loadChildren: () => import('./home/homecliente/shop/shop.module').then(m => m.ShopPageModule)
  },
  {
    path: 'historialfacturas',
    loadChildren: () => import('./home/homecliente/historialfacturas/historialfacturas.module').then(m => m.HistorialfacturasPageModule)
  },
  {
    path: 'historialasistencia',
    loadChildren: () => import('./home/homecliente/historialasistencia/historialasistencia.module').then(m => m.HistorialasistenciaPageModule)
  },
  {
    path: 'modal-image',
    loadChildren: () => import('./ADMIN/modal-image/modal-image.module').then(m => m.ModalImagePageModule)
  },
  {
    path: 'modal-pdf',
    loadChildren: () => import('./ADMIN/modal-pdf/modal-pdf.module').then(m => m.ModalPdfPageModule)
  },
  {
    path: 'reset-pass-token',
    loadChildren: () => import('./home/reset-pass-token/reset-pass-token.module').then(m => m.ResetPassTokenPageModule)
  },
  {
    path: 'confirmacion-pdf',
    loadChildren: () => import('./home/confirmacion-pdf/confirmacion-pdf.module').then(m => m.ConfirmacionPdfPageModule)
  },
  {
    path: 'homecliente',
    loadChildren: () => import('./home/homecliente/homecliente.module').then(m => m.HomeclientePageModule)
  },
  {
    path: 'sanitas',
    loadChildren: () => import('./ADMIN/sanitas/sanitas.module').then(m => m.SANITASPageModule)
  },
  {
    path: 'informes',
    loadChildren: () => import('./ADMIN/informes/informes.module').then(m => m.InformesPageModule)
  },






];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
