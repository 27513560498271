import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest, HttpParams } from '@angular/common/http';

import { map, catchError, retry, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { RutasService } from './rutas.service';


@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  httpHeader = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };


  RUTA = "Misproductos";
  // RUTAARTICULOS = "MisArticulos";
  RUTAARTICULOS = "Articulos.php";
  RUTASUBIRARTICULOS = "CrearArticuloNuevo";
  AsistenciaRuta: "AsistenciaTecnica";

  BorrarArticulos = "BorrarArticulos";
  EditarArticulos = "EditarArticulos";
  Listarpromociones = "ListarPromociones";
  ListarAsistenciaTecnica = "ListarAsistenciaTecnica";



  constructor(
    public _apiruta: RutasService,
    private http: HttpClient
  ) { }


  httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json',
      'Content-Type': 'x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization,Origin, X-Requested-With, Content-Type, Accept',
      'Access-Control-Allow-Credentials': 'true',
      method: 'POST'
    })
  };



  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }


  listarAsistenciaTecnica(datos) {

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');


    const payload = new HttpParams()
      .set('CIFDNI', datos)


    // console.log(p);


    return this.http.post(this._apiruta.BASE_RUTA + this.ListarAsistenciaTecnica, payload)
      .pipe(map(
        dat => {
          // console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));

  }



  listarpromociones() {

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');


    const payload = new HttpParams()
    //  .set('CIFDNI', datos)


    // console.log(p);


    return this.http.post(this._apiruta.BASE_RUTA + this.Listarpromociones, payload)
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));

  }

  shopproducts(cifdni) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');


    const payload = new HttpParams()
      .set('CIFDNI', cifdni)

    return this.http.post(this._apiruta.BASE_RUTA + this.RUTA + "/shopproducts", payload)
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));
  }


  listarproductoscliente(datos) {

    // console.log(datos);


    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');


    const payload = new HttpParams()
      .set('CIFDNI', datos)


    // console.log(p);


    return this.http.post(this._apiruta.BASE_RUTA + this.RUTA, payload)
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));

  }





  listararticuloscliente() {


    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');


    const payload = new HttpParams()


    // console.log(p);


    return this.http.post(this._apiruta.BASE_RUTASQL + this.RUTAARTICULOS, payload)
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));

  }





  crearArticulosNuevos(datos, dat) {


    // console.log(datos[0]);
    // console.log(dat);

    let headers = new HttpHeaders()
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');

    const formData = new FormData();
    formData.append('file', datos[0]);
    formData.append('codigoArticulo', dat['codigoArticulo']);
    formData.append('fecha', dat['fecha']);
    formData.append('numeroserie', dat['numeroserie']);
    formData.append('CIFDNI', dat['CIFDNI']);
    formData.append('RazonSocial', dat['RazonSocial']);
    formData.append('Email', dat['Email']);
    formData.append('asistencia', dat['asistencia']);
    formData.append('garantia', dat['garantia']);
    formData.append('tipo', dat['tipo']);
    // return this.http.post<any>('http://ccoulter12.lampt.eeecs.qub.ac.uk/api/fileUpload.php', formData, {headers});


    // const payload = new HttpParams()
    // .set('file', datos[0])


    // console.log(p);


    return this.http.post(this._apiruta.BASE_RUTA + this.RUTASUBIRARTICULOS, formData, { headers })
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));

  }




  crearAasistencia(asistencia, productos, direcciones) {


    console.log(asistencia);
    console.log(productos);
    console.log(direcciones);
    // console.log(dat);


    var headers = new HttpHeaders();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');


    const payload = new HttpParams()
      .set('asistencia', asistencia)
      .set('productos', productos)
      .set('direcciones', direcciones)

    // console.log(p);


    return this.http.post(this._apiruta.BASE_RUTA + this.AsistenciaRuta, payload, { headers })
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));

  }





  borrararticulo(datos) {

    //console.log(datos);


    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');


    const payload = new HttpParams()
      .set('CIFDNI', datos['CIFDNI'])
      .set('NumeroSerie', datos['NumeroSerie'])


    // console.log(p);


    return this.http.post(this._apiruta.BASE_RUTA + this.BorrarArticulos, payload)
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));

  }



  EditarArticulosNuevos(datos, dat) {


    //console.log(datos);
    //console.log(dat);


    //  CIFDNI: "123456789"
    //   Email: "jose@jm2informatica.com"
    //   RazonSocial: "JM2 INFORMATICA"
    //   codigoArticulo: "00000120"
    //   fecha: "2016-03-16"
    //   id: "2118"
    //   numeroserie: "jose1234234"


    // console.log(dat);

    let headers = new HttpHeaders()
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');



    const formData = new FormData();
    if (typeof datos != 'undefined') {
      formData.append('file', datos[0]);
    }
    formData.append('id', dat['id']);
    formData.append('codigoArticulo', dat['codigoArticulo']);
    formData.append('fecha', dat['fecha']);
    formData.append('numeroserie', dat['numeroserie']);
    formData.append('CIFDNI', dat['CIFDNI']);
    formData.append('RazonSocial', dat['RazonSocial']);
    formData.append('Email', dat['Email']);
    formData.append('tipo', dat['tipo']);

    // const payload = new HttpParams()
    // .set('file', datos[0])


    // console.log(p);


    return this.http.post(this._apiruta.BASE_RUTA + this.EditarArticulos, formData, { headers })
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));

  }




}
