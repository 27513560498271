import { Component, OnInit, Input } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-ampliacion-garantia',
  templateUrl: './ampliacion-garantia.page.html',
  styleUrls: ['./ampliacion-garantia.page.scss'],
})
export class AmpliacionGarantiaPage implements OnInit {

  @Input() articulo: {};

  constructor(  public modalController: ModalController,
                public navCtrl: NavController
                ) { }

  ngOnInit() {
    
  }

  ionViewWillEnter() {
    console.log("TEST", this.articulo);

  }

  closemodal() {
    this.modalController.dismiss();
  }

  cerrarConCompraGarantia(categoria, texto, indice, precio) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        garantia_desde_misproductos: {
            "categoria": categoria,
            "texto": texto,
            "indice": indice,
            "precio": precio
          }
      }
    };
    this.navCtrl.navigateForward("/shop" , navigationExtras );
    this.modalController.dismiss();
  }
}
