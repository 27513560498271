import { Component, OnInit, ViewChild  } from '@angular/core';
import { IonSlides, ModalController, NavController } from '@ionic/angular';
import { CrearusuarioPage } from 'src/app/home/crearusuario/crearusuario.page';
import { CrudTablasService } from '../../servicios/crud-tablas.service';


@Component({
  selector: 'app-sliders',
  templateUrl: './sliders.component.html',
  styleUrls: ['./sliders.component.scss'],
})
export class SlidersComponent implements OnInit {

  @ViewChild('slideWithNav', { static: false }) slideWithNav: IonSlides;
  @ViewChild('slideWithNav2', { static: false }) slideWithNav2: IonSlides;
  @ViewChild('slideWithNav3', { static: false }) slideWithNav3: IonSlides;
  @ViewChild('slideWithNav4', { static: false }) slideWithNav4: IonSlides;

  sliderOne: any;
  sliderTwo: any;
  sliderThree: any;



  //Configuration for each Slider
  slideOptsOne = {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: true
  };
  slideOptsTwo = {
    initialSlide: 1,
    slidesPerView: 2,
    loop: true,
    centeredSlides: true,
    spaceBetween: 20
  };
  slideOptsThree = {
    initialSlide: 0,
    slidesPerView: 3
  };

  slideOptsFor = {
    initialSlide: 0,
    slidesPerView: 4
  };


  public sliderOptions = {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: true,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true
    }
  };

  modelData: any;



  constructor(

    public navCtrl: NavController,
    public modalCtrl : ModalController,
    public modalController: ModalController,
    private crudTabla: CrudTablasService

  ) {

    //Item object for Nature
    this.sliderOne =
    {
      isBeginningSlide: true,
      isEndSlide: false,
      slidesItems: [

      ]
    };
    this.crudTabla.getSliders().subscribe( ( ans:any ) => {
      //console.log(ans);
      this.sliderOne.slidesItems.push(...ans.data);
      //console.log(this.sliderOne);

    });

  }

  //Move to Next slide
  slideNext(object, slideView) {
    slideView.slideNext(1000).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });
  }

  //Move to previous slide
  slidePrev(object, slideView) {
    slideView.slidePrev(1000).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });;
  }

  //Method called when slide is changed by drag or navigation
  SlideDidChange(object, slideView) {
    this.checkIfNavDisabled(object, slideView);
  }

  //Call methods to check if slide is first or last to enable disbale navigation
  checkIfNavDisabled(object, slideView) {
    this.checkisBeginning(object, slideView);
    this.checkisEnd(object, slideView);
  }

  checkisBeginning(object, slideView) {
    slideView.isBeginning().then((istrue) => {
      object.isBeginningSlide = istrue;
    });
  }
  checkisEnd(object, slideView) {
    slideView.isEnd().then((istrue) => {
      object.isEndSlide = istrue;
    });
  }

  ngOnInit() {

  }


  async crearusuarionuevo() {
    const modal = await this.modalController.create({
      component: CrearusuarioPage,
      cssClass: "wideModal",
      componentProps: {
        'model_title': "Crear usuario nuevo"
      }
    });

    modal.onDidDismiss().then((modelData) => {
      if (modelData !== null) {
        this.modelData = modelData.data;
        // console.log('Modal Data : ' + modelData.data);
      }
    });

    return await modal.present();
  }


}
