import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest, HttpParams } from '@angular/common/http';

import { map , catchError, retry, tap } from 'rxjs/operators';
import { Observable , of, throwError } from 'rxjs';
import { RutasService } from './rutas.service';





@Injectable({
  providedIn: 'root'
})
export class LoginService {


  httpHeader = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };


  RUTA = "Login";
  RUTA2 = "ResetPass";
  RUTA3 = "ResetPass/correo";
  RUTA4 = "ResetPass/reset";


  constructor( public _apiruta: RutasService, private http: HttpClient ) {




   }


   httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json',
      'Content-Type': 'x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization,Origin, X-Requested-With, Content-Type, Accept',
      'Access-Control-Allow-Credentials': 'true',
      method: 'POST'
    })
  };



  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }




  // SACA TODOS LOS USUARIOS PASANDO LA API PARA HACER EL LOGIN
  loginUser( datos ) {

    // console.log(datos);


    var headers = new Headers();
     headers.append("Accept", 'application/json');
     headers.append('Content-Type', 'application/json' );


    const payload = new HttpParams()
    .set('email', datos.email)
    .set('password', datos.password);


    // console.log(p);


    return this.http.post(this._apiruta.BASE_RUTA+this.RUTA , payload)
    .pipe(map(
      dat => {
        // console.log('res ' + dat);
        return dat;
      },
      err =>
        console.log(err)
    ));

  }

  generateToken( usuario ) {
    const payload = new HttpParams()
    .set('email', usuario.email)
    return this.http.post(this._apiruta.BASE_RUTA+this.RUTA2 , payload)
    .pipe(map(
      dat => {
        // console.log('res ' + dat);
        return dat;
      },
      err =>
        console.log(err)
    ));
  }

  resetPassword( usuario ) {
    const payload = new HttpParams()
    .set('email', usuario.email)
    .set('token', usuario.token)

    return this.http.post(this._apiruta.BASE_RUTA+this.RUTA3 , payload)
    .pipe(map(
      dat => {
        // console.log('res ' + dat);
        return dat;
      },
      err =>
        console.log(err)
    ));
  }

  nuevaPassword( usuario ) {
    const payload = new HttpParams()
    .set('password', usuario.password)
    .set('token', usuario.token)

    return this.http.post(this._apiruta.BASE_RUTA+this.RUTA4 , payload)
    .pipe(map(
      dat => {
        // console.log('res ' + dat);
        return dat;
      },
      err =>
        console.log(err)
    ));
  }

}
