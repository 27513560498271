import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  cookieMessage: "Este sitio web utiliza cookies para que usted tenga la mejor experiencia de usuario, si continúa navegando está dando su consentimiento para la aceptación de las mencionadas cookies y la aceptación de nuestra";
  cookieDismiss: "ACEPTAR";
  cookieLinkText: "Política de cookies";

  constructor() {

    // solo es quitar los comentarios y ya funcionaría las cookies
    // let cc = window as any;
    // cc.cookieconsent.initialise({
    //   cookie: {
    //     domain: 'https://www.whiberica.es' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    //   },
    //   palette: {
    //     popup: {
    //       background: "#164969",
    //       text: "#ffffff",
    //     },
    //     button: {
    //       background: "#ffe000",
    //       text: "#164969"
    //     }
    //   },
    //   type: "info",
    //   theme: "classic",
    //   content: {
    //     message: "Este sitio web utiliza cookies para que usted tenga la mejor experiencia de usuario, si continúa navegando está dando su consentimiento para la aceptación de las mencionadas cookies y la aceptación de nuestra",
    //     dismiss: "Aceptar",
    //     deny: "Rechazar cookies",
    //     link: "Política de cookies",
    //     href: "https://www.wh.com/es_iberica/informacion-legal/",
    //     policy: "Cookie Policy"
    //   }

    // });

  }
}
