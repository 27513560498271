import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { StorageService } from '../../../servicios/storage.service';
import { Todo } from '../../../interface/usuario';
import { ProductosService } from '../../../servicios/productos.service';


@Component({
  selector: 'app-historialfacturas',
  templateUrl: './historialfacturas.page.html',
  styleUrls: ['./historialfacturas.page.scss'],
})
export class HistorialfacturasPage implements OnInit {

  public todos: Array<Todo> = [];
  validado: any;
  validar: any;


  newarray_CIR: any = [];
  newarray_CLI: any = [];
  newarray_ESM: any = [];
  newarray_LAB: any = [];
  newarray_UES: any = [];
  newarray_OSS: any = [];


  menuproductos: boolean = false;
  menuasistencia: boolean = false;
  menuhistorialasistenciatecnica: boolean = false;
  menuhistorialfacturas: boolean = true;


  constructor(public navCtrl: NavController,
    private _misproductos: ProductosService,
    private _storage: StorageService,
  ) { }

  async ngOnInit() {

    await this._storage.validarusuario().then((result) => {
      this.validado = result;
    });

    await this._storage.validar().then((result) => {
      this.validar = result;
    });

    if (this.validado) {


      if (this.validar == "si") {

        this.todos = await this._storage.read();
        await this.iniciarlistadoproductos();

      } else {

        await this._storage.createTodo();
        this.todos = await this._storage.read();
        await this.iniciarlistadoproductos();

      }


    } else {
      this._storage.val = "no";
      // console.log(this.validado);
      this._storage.cerrarsesion();
      this.navCtrl.navigateRoot('/home');

    }


  }
  calcularTipoGarantia(meses) {
    switch (meses) {
      case "24":
        return "Garantía de 2 años";
      case "36":
        return "Garantía de 3 años";
      default:
        return "Garantía de 1 año";
        break;
    }
  }

  solicitarasistencia() {
    this.navCtrl.navigateForward('/asistenciatecnica');
  }


  micuenta() {
    this.navCtrl.navigateForward('/micuenta');
  }

  shop() {
    this.navCtrl.navigateForward('/shop');
  }

  async iniciarlistadoproductos() {
    let cif = this.todos[0]['CIFDNI'];
    await this._misproductos.listarproductoscliente(cif).subscribe(async (ans) => {   // EL ORIGINAL
      for (const [key, value] of Object.entries(ans)) {
        if (value.length) {
          for (let index = 0; index < value.length; index++) {
            await this._misproductos.shopproducts(cif).subscribe(async (test: any) => {
              if (test.length) {
                for (let pos = 0; pos < test.length; pos++) {
                  let TieneGarantia = false;
                  if (value[index]["Referencia"] == test[pos]['Referencia']) TieneGarantia = true;
                  if (TieneGarantia) {
                    value[index]["FechaCompraGarantia"] = test[pos]['FechaCompraGarantia'];
                    value[index]["TipoGar"] = test[pos]['TipoGar'];
                    value[index]["Precio"] = test[pos]['Precio'];
                    switch (key) {
                      case "CIR":
                        this.newarray_CIR.push(...value[index]);
                        break;
                      case "CLI":
                        this.newarray_CLI.push(...value[index]);
                        break;
                      case "ESM":
                        this.newarray_ESM.push(...value[index]);
                        //console.log(this.newarray_ESM);
                        break;
                      case "LAB":
                        this.newarray_LAB.push(...value[index]);
                        break;
                      case "UES":
                        this.newarray_UES.push(...value[index]);
                        break;
                      case "OSS":
                        this.newarray_OSS.push(...value[index]);
                        break;
                      default:
                        break;
                    }
                    pos = test.length;
                  }
                }
              }
            });
          }
        }
      }
      //console.log("prueba", this.newarray_ESM);
    });

  }


}
