import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Storage } from '@ionic/storage-angular';
import { LoadingController, NavController, ToastController } from '@ionic/angular';

import { Todo } from '../interface/usuario';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  usuarioiniciado: any;
  list: any;


  items = [];
  StorageItem: any;
  val = "no";

  public todos: Array<Todo> = [];

  datos: { key: string; id: number; CIFDNI: any; RazonSocial: any; NombreComercial: any; Nombre: any; Apellidos: any; Telefono: any; Email: any; Pais: any; CodigoSigla: any; Escalera: any; Numero: any; Piso: any; Puerta: any; Letra: any; CodPostal: any; Calle: any; Poblacion: any; Provincia: any; };
  validado: any;



  constructor(

    private router: Router,
    private storage: Storage,
    private toastController: ToastController,
    public navCtrl: NavController,

  ) {

    this.storage.create();


  }


  async validarAdmin() {


    this.storage.get('validado').then(ans => {

      console.log("ans" + ans);

      if (ans === "si") {
        this.storage.get('Permiso').then(ans2 => {
          console.log("ans2" + ans2);

          if (ans2 === "administrador") {
            this.navCtrl.navigateForward("/dashboard");
          } else {
            this.navCtrl.navigateForward("/homecliente");
          }
        });
      } else {
        this.navCtrl.navigateForward("/homecliente");
      }
    })


  }

  async recogerShop() {
    return await this.storage.get('shop');
  }

  async almacenarShop(array) {


    let test = {
      "ID_produc": ["gar1", "gar2"],
    }

    this.storage.set('validado', 'si');
  }

  async validarpermisos() {
    return await this.storage.get('Permiso');
  }


  async validarCECO() {
    return await this.storage.get('ceco');
  }


  async validarusuario() {


    return await this.storage.get('insesion');

  }

  async validar() {

    return this.storage.get('validado');

  }



  cerrarsesion() {

    this.val = "no";
    this.storage.clear();

  }




  public async generateKey(): Promise<string> {


    // console.log(this.val);

    let key = `todo${parseInt(`${Math.random() * 100}`)}`;
    let ret = await this.storage.get(key);

    while (ret) {
      key = `todo${parseInt(`${Math.random() * 100}`)}`;
      ret = await this.storage.get(key);
    }
    return key;

  }


  public async read(): Promise<Todo[]> {

    let todos: Array<Todo> = [];
    await this.storage.forEach((v, key, i) => {
      if (key.startsWith("todo")) {
        todos.push(v);
      }
    });

    return todos;

  }

  public async create(key: string, todo: Todo) {


    // console.log("Creating todo: ", todo);
    // console.log("Creating todo: ", this.val);

    return await this.storage.set(key, todo);

  }

  public async update(todo: Todo) {
    return await this.storage.set(todo.key, todo);
  }

  public async delete(key: string) {
    return await this.storage.remove(key);
  }



  public async createTodo() {



    this.storage.set('validado', 'si');

    let key = await this.generateKey();
    this.datos = {
      key: key,
      id: await this.storage.get('id'),
      CIFDNI: await this.storage.get('CIFDNI'),
      RazonSocial: await this.storage.get('razonsocial'),
      NombreComercial: await this.storage.get('NombreComercial'),
      Telefono: await this.storage.get('telefono'),
      Email: await this.storage.get('email'),
      Pais: await this.storage.get('pais'),
      CodigoSigla: await this.storage.get('codigosigla'),
      Escalera: await this.storage.get('escalera'),
      Numero: await this.storage.get('numero'),
      Piso: await this.storage.get('piso'),
      Puerta: await this.storage.get('puerta'),
      Letra: await this.storage.get('letra'),
      CodPostal: await this.storage.get('codpostal'),
      Calle: await this.storage.get('calle'),
      Poblacion: await this.storage.get('poblacion'),
      Provincia: await this.storage.get('provincia'),
      Nombre: await this.storage.get('Nombre'),
      Apellidos: await this.storage.get('Apellidos')
    };
    await this.create(key, this.datos);
    this.todos = await this.read();


  }






}
