import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, Validators, NgForm } from '@angular/forms';

import { LoginService } from 'src/app/servicios/login.service';
import { LoadingController, ModalController, NavController, NavParams, ToastController, AlertController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { HomeclientePage } from '../homecliente/homecliente.page';
import { Storage } from '@ionic/storage-angular';
import { StorageService } from 'src/app/servicios/storage.service';
import { MicuentaService } from 'src/app/servicios/micuenta.service';
import { CountrySelectService } from 'src/app/servicios/country-select.service';
import { Todo } from 'src/app/interface/usuario';
import { RecuperarContraPage } from '../homecliente/modals/recuperar-contra/recuperar-contra.page';
declare var $: any;




@Component({
  selector: 'app-crearusuario',
  templateUrl: './crearusuario.page.html',
  styleUrls: ['./crearusuario.page.scss'],
  providers: [NavParams]
})
export class CrearusuarioPage implements OnInit {


  login = new FormGroup({
    email: new FormControl('', Validators.required),
    passsword: new FormControl('', Validators.required)
  });



  user = {
    "email": '',
    "password": ''
  }

  rep_pass: string;
  razonsocial: any;
  empresa: any;
  apellidos: any;
  nombre: any;
  email: any;
  pais: any;
  codigosigla: any;
  escalera: any;
  numero: any;
  piso: any;
  password: any;
  password2: any;
  puerta: any;
  CIFDNI: any;
  telefono: any;
  letra: any;
  codpostal: any;
  calle: any;
  ciudad: any;
  poblacion: any;
  provincia: any;
  evalue: any[];
  datos: Todo[] = [];
  id: any;

  checkbox: boolean = true;
  checkbox1: boolean = true;
  checkbox2: boolean = false;
  checkbox3: boolean;
  checkbox4: boolean;
  checkbox5: boolean;
  cifDniPattern = /^[a-zA-Z0-9]{1,12}$/; // Esto permitirá letras mayúsculas y minúsculas, así como números, con una longitud de hasta 12 caracteres.


  copiaArrayArchivos: Todo[] = [];

  copiaArrayArchivosprovincias: any = [];
  copiaArrayArchivossiglas: any = [];
  copiaArrayArchivoslistadopaises: any = [];


  // AUX BUG ----------------------------------------------------------------------------------------------------------------
  paises = Array();
  pais_default: string;
  pais_text_default: string;

  provincias = Array();
  provincia_default: string;
  provincia_text_default: string;

  siglas = Array();
  sigla_default: string;
  sigla_text_default: string;

  aux_bug: boolean = false;
  aux_prov: string;
  aux_sigla: string;
  cif: string;
  dat: Object;
  respuesta: any;
  Nombre: any;
  Apellidos: any;
  isIndeterminate: boolean = true;
  isIndeterminate1: boolean = true;
  token: any;
  // AUX BUG ----------------------------------------------------------------------------------------------------------------





  constructor(
    private loginService: LoginService,
    private toastController: ToastController,
    public navCtrl: NavController,
    private router: Router,
    private storage: Storage,
    public loadingCtrl: LoadingController,
    public modalCtrl: ModalController,
    public navParams: NavParams,
    private _storage: StorageService,
    private _micuenta: MicuentaService,
    private countrySelect: CountrySelectService,
    public alertController: AlertController,
    public modalController: ModalController,
  ) {

  }

  ngOnInit() {

    // $(document).ready(function () {
    //   alert("Hello from New to design")
    // });

    this.checkbox = true;
    this.checkbox1 = true;

  }


  async ionViewWillEnter() {

    // SELECTS DIRECCIONES PAISES
    this.pais_default = "ES-España";
    this.pais_text_default = "España";
    this.provincia_default = "46-VALENCIA/VALÉNCIA";
    this.provincia_text_default = "VALENCIA/VALÉNCIA";
    this.sigla_default = "9-Calle";
    this.sigla_text_default = "Calle";




    await this.iniciocreardirecciones();
    await this.loadPaises();


  }




  async onSubmit(formulario: NgForm) {


    //console.log(formulario);


    this.loginService.loginUser(this.user).subscribe(async (ans) => {   // EL ORIGINAL

      //console.log(ans);
      this.dat = ans;

      if (ans == 'error') {


        const toast = await this.toastController.create({
          message: "Error en el loguin, usuario o contraseña erroneos, intentelo otra vez",
          duration: 4000,
          position: 'middle'
        });
        toast.present();
        await this.storage.set('insesion', 'no');

        //this.navCtrl.navigateRoot('/home');

      } else {

        this.id = ans[0]['cid'];
        this.razonsocial = ans[0]['RazonSocial'];
        this.CIFDNI = ans[0]['CIFDNI'];
        this.telefono = ans[0]['Telefono'];
        this.email = ans[0]['Email'];
        this.pais = ans[0]['Pais'];
        this.codigosigla = ans[0]['CodigoSigla'];
        this.escalera = ans[0]['Escalera'];
        this.numero = ans[0]['Numero'];
        this.piso = ans[0]['Piso'];
        this.puerta = ans[0]['Puerta'];
        this.letra = ans[0]['Letra'];
        this.codpostal = ans[0]['CodPostal'];
        this.calle = ans[0]['Calle'];
        this.poblacion = ans[0]['Poblacion'];
        this.provincia = ans[0]['Provincia'];
        this.Nombre = ans[0]['Nombre'];
        this.Apellidos = ans[0]['Apellidos'];


        await this.storage.set('insesion', 'si');

        await this.storage.set('razonsocial', this.razonsocial);
        await this.storage.set('id', this.id);
        await this.storage.set('CIFDNI', this.CIFDNI);
        await this.storage.set('telefono', this.telefono);
        await this.storage.set('email', this.email);
        await this.storage.set('pais', this.pais);
        await this.storage.set('codigosigla', this.codigosigla);
        await this.storage.set('escalera', this.escalera);
        await this.storage.set('numero', this.numero);
        await this.storage.set('piso', this.piso);
        await this.storage.set('puerta', this.puerta);
        await this.storage.set('letra', this.letra);
        await this.storage.set('codpostal', this.codpostal);
        await this.storage.set('calle', this.calle);
        await this.storage.set('poblacion', this.poblacion);
        await this.storage.set('provincia', this.provincia);
        await this.storage.set('Nombre', this.Nombre);
        await this.storage.set('Apellidos', this.Apellidos);

        // this.messageEvent.emit(this.datos);

        this.modalCtrl.dismiss({
          'dismissed': true
        });

        this.navCtrl.navigateRoot('/homecliente', ans);
      }

    });
  }


  async register(formulario: NgForm) {

    //console.log(formulario.controls);



    // console.log(formulario.controls.Apellidos.value);
    // console.log(formulario.controls.CIFDNI.value);
    // console.log(formulario.controls.Ciudad.value);
    // console.log(formulario.controls.Email.value);
    // console.log(formulario.controls.Empresa.value);
    // console.log(formulario.controls.Escalera.value);
    // console.log(formulario.controls.Letra.value);
    // console.log(formulario.controls.Nombre.value);
    // console.log(formulario.controls.Nombrevia.value);
    // console.log(formulario.controls.Numero.value);
    // console.log(formulario.controls.Pais.value);
    // console.log(formulario.controls.Password.value);
    // console.log(formulario.controls.Password2.value);
    // console.log(formulario.controls.Piso.value);
    // console.log(formulario.controls.Provincia.value);
    // console.log(formulario.controls.Puerta.value);
    // console.log(formulario.controls.Siglas.value);
    // console.log(formulario.controls.Telefono.value);
    // console.log(formulario.controls.CodPostal.value);
    // console.log(formulario.controls.checkbox.value);
    // console.log(formulario.controls.checkbox1.value);
    // console.log(formulario.controls.checkbox2.value);
    // console.log(formulario.controls.checkbox3.value);
    // console.log(formulario.controls.checkbox4.value);

    let sigla = formulario.controls.Siglas.value;
    let pais = formulario.controls.Pais.value;
    let provincia = formulario.controls.Provincia.value;

    if (sigla === this.sigla_default) {

      sigla = this.sigla_text_default;

    }

    if (pais === this.pais_default) {

      pais = this.pais_text_default;

    }

    if (provincia === this.provincia_default) {

      provincia = this.provincia_text_default;

    }




    let usuarionuevo = {
      "Apellidos": formulario.controls.Apellidos.value,
      "CIFDNI": formulario.controls.CIFDNI.value,
      "Ciudad": formulario.controls.Ciudad.value,
      "Email": formulario.controls.Email.value,
      "Empresa": formulario.controls.Empresa.value,
      "Escalera": formulario.controls.Escalera.value,
      "Letra": formulario.controls.Letra.value,
      "Nombre": formulario.controls.Nombre.value,
      "Nombrevia": formulario.controls.Nombrevia.value,
      "Numero": formulario.controls.Numero.value,
      "Pais": pais,
      "Password": formulario.controls.Password.value,
      "Piso": formulario.controls.Piso.value,
      "Provincia": provincia,
      "Puerta": formulario.controls.Puerta.value,
      "Siglas": sigla,
      "Telefono": formulario.controls.Telefono.value,
      "CodPostal": formulario.controls.CodPostal.value,
      "checkbox": formulario.controls.checkbox.value,
      "checkbox1": formulario.controls.checkbox1.value,
      "checkbox2": formulario.controls.checkbox2.value,
      "checkbox3": formulario.controls.checkbox3.value,
      //"checkbox4": formulario.controls.checkbox4.value,

    }


    if (formulario.controls.Password.value != formulario.controls.Password2.value) {

      const toast = await this.toastController.create({
        message: "Error las contraseñas no son iguales",
        duration: 4000,
        position: 'middle'
      });
      toast.present();

      return;

    } else {


      this._micuenta.anadircuentausuario(usuarionuevo).subscribe(async (ans: []) => {


        this.respuesta = ans;

        //console.log(this.respuesta['error']);

        if (this.respuesta['error'] == 'error') {

          const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Error al Crear usuario',
            subHeader: '',
            message: this.respuesta['texto'],
            buttons: ['OK']
          });

          await alert.present();

          const { role } = await alert.onDidDismiss();
          //console.log('onDidDismiss resolved with role', role);

          // this.loadingController.dismiss();


        } else {


          const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Usuario Creado Correctamente',
            subHeader: '',
            message: "",
            buttons: ['OK']
          });

          await alert.present();

          const { role } = await alert.onDidDismiss();
          //console.log('onDidDismiss resolved with role', role);



          let logueo = {
            "email": formulario.controls.Email.value,
            "password": formulario.controls.Password.value
          }


          this.loginService.loginUser(logueo).subscribe(async (ans) => {   // EL ORIGINAL

            //console.log(ans);


            if (ans == 'error') {


              const toast = await this.toastController.create({
                message: "Error en el loguin, usuario o contraseña erroneos, intentelo otra vez",
                duration: 4000,
                position: 'middle'
              });
              toast.present();
              await this.storage.set('insesion', 'no');

              //this.navCtrl.navigateRoot('/home');

            } else {



              const loading = await this.loadingCtrl.create({
                spinner: 'dots',
                message: 'Cargando espera unos segundos...',
                duration: 2000,
              });

              await loading.present();

              this.id = ans[0]['cid'];
              this.razonsocial = ans[0]['RazonSocial'];
              this.CIFDNI = ans[0]['CIFDNI'];
              this.telefono = ans[0]['Telefono'];
              this.email = ans[0]['Email'];
              this.pais = ans[0]['Pais'];
              this.codigosigla = ans[0]['CodigoSigla'];
              this.escalera = ans[0]['Escalera'];
              this.numero = ans[0]['Numero'];
              this.piso = ans[0]['Piso'];
              this.puerta = ans[0]['Puerta'];
              this.letra = ans[0]['Letra'];
              this.codpostal = ans[0]['CodPostal'];
              this.calle = ans[0]['Calle'];
              this.poblacion = ans[0]['Poblacion'];
              this.provincia = ans[0]['Provincia'];


              await this.storage.set('insesion', 'si');

              await this.storage.set('razonsocial', this.razonsocial);
              await this.storage.set('id', this.id);
              await this.storage.set('CIFDNI', this.CIFDNI);
              await this.storage.set('telefono', this.telefono);
              await this.storage.set('email', this.email);
              await this.storage.set('pais', this.pais);
              await this.storage.set('codigosigla', this.codigosigla);
              await this.storage.set('escalera', this.escalera);
              await this.storage.set('numero', this.numero);
              await this.storage.set('piso', this.piso);
              await this.storage.set('puerta', this.puerta);
              await this.storage.set('letra', this.letra);
              await this.storage.set('codpostal', this.codpostal);
              await this.storage.set('calle', this.calle);
              await this.storage.set('poblacion', this.poblacion);
              await this.storage.set('provincia', this.provincia);

              // this.messageEvent.emit(this.datos);
              this.modalCtrl.dismiss({
                'dismissed': true
              });

              this.navCtrl.navigateRoot('/homecliente', ans);
            }
          });



        }

        this.loadingCtrl.dismiss();



      });



    }


  }



  checkMaster(datos): void {

    console.log(datos.target.name);
    // // console.log(datos.target.checked);
    // console.log("this.isIndeterminate", this.isIndeterminate);
    // console.log("this.isIndeterminate1", this.isIndeterminate1);


    let kickname = datos.target.name;

    if (kickname == "checkbox") {

      if (this.isIndeterminate == true || this.isIndeterminate == undefined) {
        this.isIndeterminate = false;
      } else {
        this.isIndeterminate = true;
      }

    }

    if (kickname == "checkbox1") {

      if (this.isIndeterminate1 == true || this.isIndeterminate1 == undefined) {
        this.isIndeterminate1 = false;
      } else {
        this.isIndeterminate1 = true;
      }
    }



    // console.log("isIndeterminate", this.isIndeterminate);
    // console.log("isIndeterminate1", this.isIndeterminate1);




  }





  async loadPaises() {
    await this.countrySelect.getPaises()
      .subscribe(resp => {
        this.paises.push(...resp);
      });
  }

  async cambiarPais(pais) {
    // console.log(pais);

    let datos_pais = pais.value.split("-");
    this.pais_default = pais.value;
    this.pais_text_default = datos_pais[1];
    await this.loadProvincias({ "Pais": datos_pais[0] }, this.aux_bug);
    await this.loadSiglas({ "IdiomaSigla": datos_pais[0] }, this.aux_bug);
    this.aux_bug = true;
  }

  cambiarProvincia(provincia) {
    let datos_provincia = provincia.value.split("-");
    this.provincia_default = datos_provincia[0] + "-" + datos_provincia[1];
    this.provincia_text_default = datos_provincia[1];
  }

  cambiarSigla(sigla) {
    let datos_sigla = sigla.value.split("-");
    this.sigla_default = datos_sigla[0] + "-" + datos_sigla[1];
    this.sigla_text_default = datos_sigla[1];
  }

  async loadProvincias(provincias, id?) {
    this.provincias = [];

    let bug = 1;

    await this.countrySelect.getProvincias(provincias)
      .subscribe(resp => {
        if (bug === 1) {
          if (id) {
            this.aux_prov = resp[0];
            this.provincia_default = this.aux_prov['id'] + "-" + this.aux_prov['Provincia'];
            this.provincia_text_default = this.aux_prov['Provincia'];
          }
          bug++;
        }
        this.provincias.push(...resp);
      });
  }


  async loadSiglas(sigla, id?) {
    this.siglas = [];
    let bug = 1;
    await this.countrySelect.getSiglas(sigla)
      .subscribe(resp => {
        if (bug === 1) {
          if (id) {
            this.aux_sigla = resp[0];
            this.sigla_default = this.aux_sigla['id'] + "-" + this.aux_sigla['NombreSigla'];
            this.sigla_text_default = this.aux_sigla['NombreSigla'];
            bug++;
          }
        }
        this.siglas.push(...resp);
      });
  }
  // ----------------------------------------------------------------------------------------------------------------







  async iniciocreardirecciones() {


    //RECOGEMOS LOS DATOS DEL STORAGE TODAS LAS VARIABLES
    await this._storage.read().then((result) => {

      this.datos = result;

      this.copiaArrayArchivos = [];
      this.copiaArrayArchivos.push(... this.datos);


      //console.log("this.copiaarchivosarray"+ this.copiaArrayArchivos);




    });


    //console.log(this.navParams.get('model_title'));




  }


  closemodal() {

    setTimeout(async () => {

      await this.modalCtrl.dismiss(null);

    }, 350);

    this.navCtrl.navigateRoot('/homecliente');


  }



  async recuperarContra() {
    const modal = await this.modalController.create({
      component: RecuperarContraPage,
      cssClass: 'olvidarPassword',
      componentProps: {
        'token': this.token,
      }
    });
    await modal.present();

    // const {data} = await modal.onDidDismiss(); SE EJECUTA CUANDO TERMINA LA ANIMACION
    await modal.onWillDismiss(); // SE EJECUTA AL HACER CLICK EN CERRAR(ANTES QUE LA ANIMACION)
  }


  validateInput(event: KeyboardEvent) {

    const inputElement = event.target as HTMLInputElement;
    const start = inputElement.selectionStart;
    const end = inputElement.selectionEnd;
    const currentValue = inputElement.value;

    if (event.key && event.key !== event.key.toUpperCase()) {
      // Si la tecla presionada no es ya una mayúscula, prevenimos la acción por defecto
      event.preventDefault();
      // Convertimos la tecla presionada a mayúscula y actualizamos el valor del campo de entrada
      inputElement.value = currentValue.slice(0, start) + event.key.toUpperCase() + currentValue.slice(end);
      // Restauramos la selección
      inputElement.setSelectionRange(start + 1, start + 1);
    }

    const allowedCharacters = /^[a-zA-Z0-9]*$/;
    if (!allowedCharacters.test(event.key)) {
      event.preventDefault();
    }
  }

  convertToLowerCase(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    const cursorPosition = inputElement.selectionStart; // Obtener la posición del cursor

    setTimeout(() => {
      const value = inputElement.value.toLowerCase(); // Convertir el valor completo a minúsculas
      const newValue = value.substr(0, cursorPosition) + value.charAt(cursorPosition).toLowerCase(); // Convertir solo la letra en la posición del cursor a minúsculas
      inputElement.value = newValue + value.substr(cursorPosition + 1); // Agregar el resto del valor sin cambios
    });
  }


}
