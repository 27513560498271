import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest, HttpParams } from '@angular/common/http';

import { map, catchError, retry, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { RutasService } from './rutas.service';


@Injectable({
  providedIn: 'root'
})
export class MicuentaService {

  httpHeader = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };


  RUTA = "Micuenta";
  RUTANUEVOUSUARIO = "CrearUsuarioNuevo";
  RUTAACTUALIZAR = "Actualizarmicuenta";
  RUTAANADIRNUEVA = "Nuevacuenta"
  RUTABORRAR = "Borrarcuenta";
  LISTADOPAISES = "Paises";
  LISTADOPROVINCIAS = "Provincias";
  LISTADOSIGLAS = "Siglas";
  RUTAACTUALIZARDIRECCION = "Actualizardireccionenvio"

  constructor(
    public _apiruta: RutasService,
    private http: HttpClient
  ) {

  }

  httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json',
      'Content-Type': 'x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization,Origin, X-Requested-With, Content-Type, Accept',
      'Access-Control-Allow-Credentials': 'true',
      method: 'POST'
    })
  };



  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }




  // SACA TODOS LOS USUARIOS PASANDO LA API PARA HACER EL LOGIN
  micuenta(datos) {

    // console.log(datos);


    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');


    const payload = new HttpParams()
      .set('CIFDNI', datos)


    // console.log(p);


    return this.http.post(this._apiruta.BASE_RUTA + this.RUTA, payload)
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));

  }


  actualizarcuenta(datos) {

    // console.log(datos);


    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');

    const payload = new HttpParams()
      .set('CIFDNI', datos['CIFDNI'])
      .set('id', datos['id'])
      .set('Email', datos['Email'])
      .set('Razonsocial', datos['Razonsocial'])
      .set('Telefono', datos['Telefono'])
      .set('Password', datos['Password'])
    // console.log(p);

    return this.http.post(this._apiruta.BASE_RUTA + this.RUTAACTUALIZAR, payload)
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));


  }



  anadircuentausuario(datos) {

    // console.log(datos);


    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');





    const payload = new HttpParams()
      .set('Apellidos', datos['Apellidos'])
      .set('CIFDNI', datos['CIFDNI'])
      .set('Ciudad', datos['Ciudad'])
      .set('Email', datos['Email'])
      .set('Empresa', datos['Empresa'])
      .set('Escalera', datos['Escalera'])
      .set('Letra', datos['Letra'])
      .set('Nombre', datos['Nombre'])
      .set('Nombrevia', datos['Nombrevia'])
      .set('Numero', datos['Numero'])
      .set('Pais', datos['Pais'])
      .set('Password', datos['Password'])
      .set('Piso', datos['Piso'])
      .set('Provincia', datos['Provincia'])
      .set('Puerta', datos['Puerta'])
      .set('Siglas', datos['Siglas'])
      .set('Telefono', datos['Telefono'])
      .set('CodPostal', datos['CodPostal'])
      .set('checkbox', datos['checkbox'])
      .set('checkbox1', datos['checkbox1'])
      .set('checkbox2', datos['checkbox2'])
      .set('checkbox3', datos['checkbox3'])
      .set('checkbox4', datos['checkbox4'])



    console.log(payload);


    return this.http.post(this._apiruta.BASE_RUTA + this.RUTANUEVOUSUARIO, payload)
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));

  }






  actualizardireccion(datos) {

    // console.log(datos);

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');

    const payload = new HttpParams()
      .set('id', datos['id'])
      .set('CIFDNI', datos['CIFDNI'])
      .set('Escalera', datos['Escalera'])
      .set('Calle', datos['Calle'])
      .set('Piso', datos['Piso'])
      .set('Puerta', datos['Puerta'])
      .set('Letra', datos['Letra'])
      .set('CodigoSigla', datos['CodigoSigla'])
      .set('Numero', datos['Numero'])
      .set('CodPostal', datos['CodPostal'])
      .set('Poblacion', datos['Poblacion'])
      .set('Provincia', datos['Provincia'])
      .set('Pais', datos['Pais'])

    // console.log(p);

    return this.http.post(this._apiruta.BASE_RUTA + this.RUTAACTUALIZARDIRECCION, payload)
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));


  }



  anadircuenta(datos) {


    // "CIFDNI" : this.cif,
    // "Escalera" : dato.controls.Letra.value,
    // "Calle" : dato.controls.Nombrevia.value,
    // "Piso" : dato.controls.Piso.value,
    // "Puerta" : dato.controls.Puerta.value,
    // "Letra" : dato.controls.Letra.value,
    // "CodigoSigla" : dato.controls.Siglas.value.NombreSigla,
    // "Numero" : dato.controls.Numero.value,
    // "CodPostal" : dato.controls.CodPostal.value,
    // "Poblacion" : dato.controls.Municipio.value,
    // "Provincia" : dato.controls.Provincia.value.Provincia,
    // "Pais" : dato.controls.Pais.value.Pais

    // console.log(datos);


    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');

    const payload = new HttpParams()
      .set('CIFDNI', datos.CIFDNI)
      .set('Escalera', datos.Escalera)
      .set('Calle', datos.Calle)
      .set('Piso', datos.Piso)
      .set('Puerta', datos.Puerta)
      .set('Letra', datos.Letra)
      .set('CodigoSigla', datos.CodigoSigla)
      .set('Numero', datos.Numero)
      .set('CodPostal', datos.CodPostal)
      .set('Poblacion', datos.Poblacion)
      .set('Provincia', datos.Provincia)
      .set('Pais', datos.Pais)
      .set('TipoDomicilio', datos.TipoDomicilio)

    // console.log(p);

    return this.http.post(this._apiruta.BASE_RUTA + this.RUTAANADIRNUEVA, payload)
      .pipe(map(
        dat => {
          // console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));


  }



  borrarcuenta(datos) {

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');

    const payload = new HttpParams()
      .set('id', datos)

    // console.log(p);

    return this.http.post(this._apiruta.BASE_RUTA + this.RUTABORRAR, payload)
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));


  }





  listadopaises() {

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');

    const payload = new HttpParams()
    // .set('CIFDNI', datos)

    // console.log(p);

    return this.http.post(this._apiruta.BASE_RUTA + this.LISTADOPAISES, payload)
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));


  }




  listadoprovincias(datos) {

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');

    const payload = new HttpParams()
      .set('Pais', datos)

    // console.log(p);

    return this.http.post(this._apiruta.BASE_RUTA + this.LISTADOPROVINCIAS, payload)
      .pipe(map(
        dat => {
          //console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));


  }




  listadosiglas(datos) {

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');

    const payload = new HttpParams()
      .set('IdiomaSigla', datos)

    // console.log(p);

    return this.http.post(this._apiruta.BASE_RUTA + this.LISTADOSIGLAS, payload)
      .pipe(map(
        dat => {
          // console.log('res ' + JSON.stringify(dat));
          return dat;
        },
        err =>
          console.log(err)
      ));


  }




}
