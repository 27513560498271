import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { CrearusuarioPage } from 'src/app/home/crearusuario/crearusuario.page';
import { StorageService } from 'src/app/servicios/storage.service';





@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  modelData: any;
  validado: any;

  constructor(
    public navCtrl: NavController,
    public modalCtrl : ModalController,
    public modalController: ModalController,
    private _storage : StorageService
  ) { }

  async ngOnInit() {

    await this._storage.validarusuario().then((result) => {
      this.validado = result;
    });


  }

  async crearusuarionuevo() {
    const modal = await this.modalController.create({
      component: CrearusuarioPage,
      cssClass: "wideModal",
      componentProps: {
        'model_title': "Crear usuario nuevo"
      }
    });

    modal.onDidDismiss().then((modelData) => {
      if (modelData !== null) {
        this.modelData = modelData.data;
        // console.log('Modal Data : ' + modelData.data);
      }
    });

    return await modal.present();
  }


  contacto(){
    window.open("https://www.wh.com/es_iberica/contacto/formulario-de-contacto", "_blank");
  }


  cerrarsesion(){


    this._storage.cerrarsesion();
    this.navCtrl.navigateRoot('/home');

  }

}
